/* globals document, window, cordova, require */
define('views/TocPanel',[
    'jquery',
    'URIjs',
    'soundmanager',
    'URLParser',
    'views/PanelBase',
    'templates/tocPanel',
    'templates/tocPanelContent',
    'views/DialogView',
    'views/iframeVideoLightboxView',
    'views/CarouselLightboxView',
    'views/videoLightboxView',
    'bowser'
],
function ($,
          URI,
          soundmanager,
          URLParser,
          PanelBase,
          mainTemplate,
          contentTemplate,
          DialogView,
          iframeVideoLightboxView,
          CarouselLightboxView,
          videoLightboxView,
          bowser
    )
    {

    function hideGoBackButton() {
        $('#goBack-btn').hide();
        $('div.progressbar').css('width', '100%');
        $('div.textualIndicators').css('width', '100%');
        $('div.textualIndicators').css('margin-left', '0');
    }

    return PanelBase.extend({
        initialize: function (app, onGoto, onGotoResource) {
            this._app = app;
            this._onGoto = onGoto;
            this._onGotoResource = onGotoResource;
            this._userbook = null;
            this._pageNumberMapping = {};
            this._tocUrl = null;
            this._lastChapterUid = null;
            this.render();
        },

        events: {
            'click .resource-tab': 'onResourceTabClicked',
            'click .resource .title': 'goToResource',
            'click .resource.bildegallerier .icon': 'openImageGalleryInLightbox',
            'click .resource.lyder .icon': 'openSound',
            'click .resource.videoer .icon': 'openVideo',
            'click .resource.eksterntinnhold .icon': 'openExternalResource',

            'click .btn-gotoPage': 'onGotoPage',
            'click .toggleExpand': 'onToggleExpand',
            'click .content .bookpart': 'onGotoTocEntry',
            'click .close-icon': 'onClose',

            'keyup input': 'onKeyupInput',
        },

        render: function () {
            var me = this;
            this.setElement(mainTemplate({
                'mobileApp': me._app.get('mobileApp')
            }));

            // disable goto page button on render since it's empty.
            this.$el.find('.btn-gotoPage').attr('disabled','true').addClass('notAValidPageNumber');

            return this;
        },

        setUserbook: function (userbook) {
            const me = this;
            // Remove some of the DOM elements from bookpart titles
            function cleanTitles(el) {
                $(el).find('.title').each(function(index, el) {

                    $(el).find('img,br').each(function(index, el) {
                        $(el).remove();
                    });

                    $(el).find('em').each(function(index, el) {
                        $(el).replaceWith($(el).text());
                    });

                });
            }

            if (userbook !== this._userbook) {
                if (this._userbook) {
                    // unregister events on old userbook.
                    this._userbook.off(null, null, this);
                }

                this._userbook = userbook;

                // empty toc and page numbers.
                this.$el.find('.content').empty();
                this._pageNumberMapping = {};

                if (!this._userbook) {
                    // return if setting userbook to null.
                    return;
                }

                var bookResource = this._userbook.getBookResource();
                this._tocUrl = bookResource.getTocUrl();
                this._detectMedia(bookResource);

                const temp = bookResource.getResourcesByChapter();
                let cleanResources = Object.assign({}, temp);
                for (let resource in cleanResources) {
                    this.cleanAriaLabels(cleanResources[resource].images);
                    this.cleanAriaLabels(cleanResources[resource].lyder);
                }

                this.$el.find('.content').html(contentTemplate({
                    toc: bookResource.getToc(),
                    resources: cleanResources,
                    tocBasepath: bookResource.getTocBasepath()
                }));

                if (bookResource.getPhysicalPagemap()) {
                    this._pageNumberMapping = bookResource.getPhysicalPagemap();
                    this.$el.find('.gotoPageCont .pageCount').text(bookResource.getLastPhysicalPage());
                    this.$el.addClass('has-physicalPageNumbers');
                }
                else {
                    this.$el.removeClass('has-physicalPageNumbers');
                }

                // register event handler for reading position changed.
                this._onReadingPositionCfiChanged(this._userbook, this._userbook.get('readingPositionCfi'));
                this._userbook.on('change:readingPositionCfi', this._onReadingPositionCfiChanged, this);

                this.$el.find('.tab').each(function(index, el) {
                    if (el.children.length === 1) {
                        el.children[0].classList.remove('collapsed');
                        me.setAriaExpanded($(el.children[0]), true);
                    }
                });

                // remove toc elements without resources inside
                this.$el.find('.tab.lyder .bookpart').each(function(index, el) {
                    if ($(el).find('.resource').length === 0) {
                        $(el).remove();
                    }
                    cleanTitles(el);
                });
                this.$el.find('.tab.videoer .bookpart').each(function(index, el) {
                    if ($(el).find('.resource').length === 0) {
                        $(el).remove();
                    }
                    cleanTitles(el);
                });
                this.$el.find('.tab.bildegallerier .bookpart').each(function(index, el) {
                    if ($(el).find('.resource').length === 0) {
                        $(el).remove();
                    }
                    cleanTitles(el);
                });
                this.$el.find('.tab.eksterntinnhold .bookpart').each(function(index, el) {
                    if ($(el).find('.resource').length === 0) {
                        $(el).remove();
                    }
                });
                this.displayTabOnStart();
                hideGoBackButton();
            }
        },

        cleanAriaLabels: function (liste) {
            if (!liste) {
                return;
            }
            for (let el of liste) {
                if (el.label && el.label.indexOf('<') !== -1) {
                    let streng = el.label;
                    let indexOpen = streng.indexOf('<');
                    let rest = '';
                    while (indexOpen >= 0) {
                        let indexClose = streng.indexOf('>');
                        rest = streng.substring(0, indexOpen);
                        if (indexClose+1 !== streng.length) {
                            rest+= streng.substring(indexClose+1);
                        }
                        streng = rest;
                        indexOpen = streng.indexOf('<');
                    }
                    el.label = streng;
                }
            }
        },

        onKeyupInput: function (e) {
            if (e.keyCode === 13) {
                this.onGotoPage();
                e.stopPropagation();
            }
            if (e.keyCode === 37 || e.keyCode === 39) {
                e.stopPropagation();
                return; // arrow keys, do nothing
            }
            this.onPageNumberChanged();
        },

        onKeyupBookpart: function (e) {
            if (e.keyCode === 13) {
                this.onGotoTocEntry(e);
                e.stopPropagation();
            }
        },

        setAriaExpanded: function (elem, value) {
            // om det er i nederste nivå gir det ingen mening med area-expanded
            if (elem.find('li').length === 0) {
                return;
            }
            var btn = elem.find('div button').first();
            var a = elem.find('div a').first();
            btn.attr("aria-expanded", value);
            a.attr("aria-expanded", value);
        },

        onGotoTocEntry: function (e) {
            var elem = $(e.currentTarget);
            elem.removeClass('collapsed');
            this.setAriaExpanded(elem, 'true');
            var href = elem.data('href');
            href = this._tocUrl ? new URI(href).absoluteTo(this._tocUrl).toString() : href;
            this._onGoto(href);
            this.hidePanelOnMobileScreens();
            return false;
        },

        onPageNumberChanged: function() {
            var gotoPageInput = this.$el.find('.gotoPageCont input');
            var gotoPageBtn = this.$el.find('.btn-gotoPage');
            var pageNumberVal = gotoPageInput.val();
            var errPopup = this.$el.find("#nonExistingPageNumPopup");

            errPopup.removeClass("show-nonExistingPageNumPopup").attr('hidden', 'hidden');

            // input value is empty, disable btn and set input styling to default.
            if (pageNumberVal === '') {
                gotoPageInput.removeClass('notAValidPageNumber');
                gotoPageBtn.attr('disabled','true').addClass('notAValidPageNumber');
                return;
            }

            var pageNumber = parseInt(pageNumberVal, 10);
            var lastPageNumber = parseInt(this._userbook.getBookResource().getLastPhysicalPage(), 10);
            if (isNaN(pageNumberVal) || pageNumber < 1 || pageNumber > lastPageNumber) {
                gotoPageInput.addClass('notAValidPageNumber');
                gotoPageBtn.attr('disabled','true').addClass('notAValidPageNumber');
                this._userbook.ariaAnnounce('Ugyldig sidetall');
            }
            else {
                gotoPageInput.removeClass('notAValidPageNumber');
                gotoPageBtn.removeAttr('disabled').removeClass('notAValidPageNumber');
            }
        },

        onGotoPage: function () {
            var gotoPageInput = this.$el.find('.gotoPageCont input');

            // input value is not valid, return early.
            if (gotoPageInput.hasClass('notAValidPageNumber')) {
                return false;
            }

            if (!this._pageNumberMapping[gotoPageInput.val()]) {
                var gotoPageBtn = this.$el.find('.btn-gotoPage');
                var errPopup = this.$el.find("#nonExistingPageNumPopup");
                gotoPageInput.addClass('notAValidPageNumber');
                gotoPageBtn.attr('disabled','true').addClass('notAValidPageNumber');
                errPopup.addClass("show-nonExistingPageNumPopup").removeAttr('hidden');
            }

            var url = this._pageNumberMapping[gotoPageInput.val()];
            if (url) {
                this._onGoto(url);
            }

            // send plausible event
            this._app.plausibleManager.sendEvent(
                this._userbook.get('publisherRef'),
                'Leseren',
                'Brukt sidetallsøk',
                this._userbook.getBookResource().get('title')
            );
            this.hidePanelOnMobileScreens();

            return false;
        },

        hidePanelOnMobileScreens: function() {
            if (this._app.get('mobileApp') || bowser.ipad || bowser.iphone) {
                this.panelController.hidePanel();
            }
        },

        goToResource: function (e) {
            var resource = $(e.target).closest('li.resource');
            var idref = resource.data('idref');
            var cfi = resource.data('cfi');
            this.hidePanelOnMobileScreens();
            this._onGotoResource(idref, cfi);
            return false;
        },

        openExternalResource: function (e) {
            var me = this;
            var dataUrl = $(e.target).closest('li.resource').data('data');
            var widgetDir = 'widgets/eksterntinnhold';

            this._openResource(dataUrl, widgetDir, function(data) {
                if (me._app.get('mobileApp')) {
                    let inAppBrowserRef = cordova.InAppBrowser.open(data.url, '_blank', 'location=no,closebuttoncaption=Lukk,hideurlbar=yes,hidenavigationbuttons=yes');
                    // Focus on h1 element when page is loaded (a11y)
                    inAppBrowserRef.channels.loadstop.subscribe(() => {
                        inAppBrowserRef.executeScript({code: `
                          const elementId = document.querySelector('h1, h2, h3, p');
                          if(elementId) {
                            elementId.setAttribute('tabindex', '-1');
                            elementId.focus();
                          }
                        `});
                      });
                } else {
                    window.open(data.url);
                }
            });
        },


        openImageGalleryInLightbox: function (e) {
            var me = this;
            var dataUrl = $(e.target).closest('li.resource').data('data');
            var widgetDir = 'widgets/bildegalleri';

            this._openResource(dataUrl, widgetDir, function(data) {
                data.bookResource = me._userbook.getBookResource();
                new CarouselLightboxView(data);
            });

            this.hidePanelOnMobileScreens();

            return false;
        },

        openSound: function (e) {
            var me = this;
            $(e.target).attr({ 'data-dialog-spawned': 1 });
            if (!this._app.get('isOnline')) {
                new DialogView({
                    "title": "Lydavspilling ikke tilgjengelig",
                    "message": "Lydavspilling er ikke tilgjengelig uten nettilgang. Prøv igjen når du er tilkoblet internett.",
                    "isAlert": true
                });
                return false;
            }

            var dataUrl = $(e.target).closest('li.resource').data('data');
            var widgetDir = 'widgets/lyd';

            soundmanager.preLoad();
            me.undelegateEvents();

            this._openResource(dataUrl, widgetDir, function(data) {
                soundmanager.toggle($(e.target), data.source);
                me.delegateEvents();
            });

            this.hidePanelOnMobileScreens();

            return false;
        },

        openVideo: function (e) {
            var me = this;
            if (!this._app.get('isOnline')) {
                $(e.target).attr({ 'data-dialog-spawned': 1 });
                new DialogView({
                    "title": "Videoavspilling ikke tilgjengelig",
                    "message": "Videoavspilling er ikke tilgjengelige uten nettilgang. Prøv igjen når du er tilkoblet internett.",
                    "isAlert": true
                });
                return false;
            }

            var dataUrl = $(e.target).closest('li.resource').data('data');
            var widgetDir = 'widgets/video';
            me.undelegateEvents();

            this._openResource(dataUrl, widgetDir, function(data) {
                var videoUrl = new URLParser(data.source);
                data.prevTarget = e.target;
                if ( videoUrl.isYouTube() || videoUrl.isVimeo() ) {
                    new iframeVideoLightboxView(data, me._app);
                    me.delegateEvents();
                } else {
                    new videoLightboxView(data, me._app);
                    me.delegateEvents();
                }
            });

            this.hidePanelOnMobileScreens();

            return false;
        },

        onToggleExpand: function (e) {
            var elem = $(e.currentTarget).parent().parent();
            elem.toggleClass('collapsed');
            this.setAriaExpanded(elem, ! elem.hasClass('collapsed'));
            return false;
        },

        onResourceTabKeyUp: function (e) {
            if (e.keyCode === 13) {
                this.onResourceTabClicked(e);
            }
        },

        onResourceTabClicked: function (e) {
            if ($(e.currentTarget).hasClass('selected')) {
                return;
            }

            this.$el.find('.resource-tab').attr('aria-selected','false').removeClass('selected');
            $(e.currentTarget).attr('aria-selected','true').addClass('selected');
            this.$el.find('.tab').hide();
            this.displayTab(e.currentTarget.id);
        },

        displayTabOnStart: function() {
            this.$el.find('.resource-tab').attr('aria-selected','false').removeClass('selected');
            this.$el.find('.toc-tab').attr('aria-selected','true').addClass('selected');
            this.displayTab("toc");
        },

        displayTab: function(tab) {
            switch (tab) {
                case "toc":
                    this.$el.find('.tab.toc').show();
                    break;
                case "audio":
                    this.$el.find('.tab.lyder').show();
                    break;
                case "gallery":
                    this.$el.find('.tab.bildegallerier').show();
                    break;
                case "video":
                    this.$el.find('.tab.videoer').show();
                    break;
                case "external":
                    this.$el.find('.tab.eksterntinnhold').show();
                    break;
                default:
                    throw new Error("MediaType not supported: " + tab);
            }
        },

        _onReadingPositionCfiChanged: function (userbook, value) {
            var me = this;

            this.$el.find('.content .activeChapter').removeClass('activeChapter');
            if (value) {
                var idref = JSON.parse(value).idref;
                var url = userbook.getBookResource().getUrlFromIdref(idref);
                var chapterUid = userbook.get('bookId') + '_' + url;
                var bookpart = this.$el.find('.content [data-href="' + url + '"]');
                if (chapterUid !== this._lastChapterUid) {
                    // all open chapters are closed by CSS.
                    // Selected chapter is expanded
                    this._lastChapterUid = chapterUid;
                    
                    $.each(this.$el.find('.content .bookpart').not('collapsed'), function(i, elm) {
                        $(elm).addClass('collapsed');
                        me.setAriaExpanded($(elm), false);
                    });

                    $.each(bookpart, function(i, elm) {
                        // do it in toc and resource panels
                        me._showTree($(elm));
                    });
                }

            }
        },

        _showTree: function(bookpart) {
            var me = this;
            bookpart.addClass('activeChapter');
            bookpart.removeClass('collapsed');
            this.setAriaExpanded(bookpart, true);
            var bookparts = bookpart.parents('.bookpart');
            $.each(bookparts, function(i, elm) {
                elm.classList.remove('collapsed');
                me.setAriaExpanded($(elm), true);
            });
        },

        _detectMedia: function (bookResource) {
            if (!bookResource) {
                return;
            }

            // reset tabs when switching books
            this.$el.find('.resource-tab').hide();
            this.$el.find('.toc-tab').show();

            var chapters = bookResource.getSpineItems();

            for (var i = 0; i < chapters.length; i++) {
                if (chapters[i].resources) {
                    if (chapters[i].resources.lyder || this.thereIsMediaInEksterntinnhold(chapters[i].resources.eksterntinnhold, "audio")) {
                        this.$el.find('.audio-tab').show();
                    }
                    if (chapters[i].resources.videoer || this.thereIsMediaInEksterntinnhold(chapters[i].resources.eksterntinnhold, "video")) {
                        this.$el.find('.video-tab').show();
                    }
                    if (chapters[i].resources.bildegallerier) {
                        this.$el.find('.gallery-tab').show();
                    }
                    if (chapters[i].resources.eksterntinnhold) {
                        this.$el.find('.external-tab').show();
                    }
                }
            }
        },

        thereIsMediaInEksterntinnhold: function(eksterntinnholder, mediaType) {
            if (eksterntinnholder && eksterntinnholder.length) {
                for (var i = 0; i < eksterntinnholder.length; i++) {
                    if (eksterntinnholder[i].resourceType && eksterntinnholder[i].resourceType === mediaType) {
                        return true;
                    }
                }
            }
            return false;
        },

        _openResource: function(dataUrl, widgetDir, callback) {
            var bookResource = this._userbook.getBookResource();
            var url = new URI(dataUrl).absoluteTo(bookResource.getOpfUrl()).toString();
            bookResource.getResourceUrl(url, function(err, resourceUrl) {
                $.get(
                    resourceUrl,
                    function (xmlData) {
                        var version = xmlData.documentElement.getAttribute('version');
                        var adaptor = '/adaptor01';
                        if (version === 'eb-embedvideo-1.1') {
                            adaptor = '/adaptor02';
                        }
                        require([widgetDir + '/Model', widgetDir + '/View', widgetDir + adaptor], function (Model, View, adaptor) {
                            adaptor(
                                $(xmlData),
                                bookResource.getOpfUrl(),
                                function(url, callback) {
                                    bookResource.getResourceUrl(url, callback);
                                },
                                callback
                            );
                        });

                    },
                    'xml'
                )
                    .fail(function (error) {
                        throw error;
                    });
            });
        }
    });
});

