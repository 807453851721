/* globals window, DOMParser, console */
define('dialog',['jquery'], function ($) {
    var prevFocus = null;
    function calcSpeakboxGeometry(maxBox, subjectRects, dialogConstraints) {
        // dialogConstraints : minWidth, minHeight
        // Denne algoritmen er sterkt forenklet, og gjør følgende antagelser: At funnelside bare kan være top | bottom, og at det alltid er nok plass over eller under subjektet.
        var funnelSide;
        var firstRect = subjectRects[0];
        var lastRect = subjectRects[subjectRects.length - 1]; // Kan godt være samme som firstRect

        var funnelCenter, x;

        if (firstRect.top - maxBox.y1 > maxBox.y2 - lastRect.bottom) {
            // Vi har mest plass ovenfor subject
            funnelSide = 'bottom';

            funnelCenter = firstRect.left + (firstRect.right - firstRect.left) / 2;
            x = Math.max(funnelCenter - dialogConstraints.width / 2, maxBox.x1);
            x = Math.min(x, maxBox.x2 - dialogConstraints.width);

            return {
                x: x,
                y2: firstRect.top,
                //maxWidth: 100,
                //maxHeight: 100,
                funnelSide: funnelSide,
                funnelPlacement: funnelCenter - x
            };
        } else {
            // Mest plass nedenfor subject
            funnelSide = 'top';

            funnelCenter = lastRect.left + (lastRect.right - lastRect.left) / 2;
            x = Math.max(funnelCenter - dialogConstraints.width / 2, maxBox.x1);
            x = Math.min(x, maxBox.x2 - dialogConstraints.width);

            return {
                x: x,
                y: lastRect.bottom,
                //maxWidth: 100,
                //maxHeight: 100,
                funnelSide: funnelSide,
                funnelPlacement: funnelCenter - x
            };
        }
    }

    // NOTE: Ville kanskje vært bedre som superklasse til andre dialoger
    function buildDialogWithFunnel(subject, dialogType) {
        var margin = 20;
        var rects = [];
        var iframeRect = $('#epub-reader-container iframe')[0].getBoundingClientRect();
        var clientRects = subject.getClientRects();

        for (var i = 0; i !== clientRects.length; i++) {
            var rect = clientRects[i];
            rects.push({
                top: rect.top + iframeRect.top,
                right: rect.right + iframeRect.left,
                left: rect.left + iframeRect.left,
                bottom: rect.bottom + iframeRect.top
            });
        }
        var geometry = calcSpeakboxGeometry({
            x1: margin,
            y1: margin,
            x2: window.innerWidth - margin * 2,
            y2: window.innerHeight - margin * 2
        }, rects, { width: 400 });


        var blockingLayerE = $('<div class="invisibleBlockingLayer"/>');
        var dialogE = $('<div class="dialogWithFunnel"></div>').addClass(dialogType);
        var funnelE = $('<div class="funnel"><div class="funnelDaimond"></div></div>');
        dialogE.append(funnelE);

        var funnelHeight = 20;
        if (geometry.funnelSide === 'top') {
            dialogE.addClass('funnelside-top');
            funnelE.css('left', geometry.funnelPlacement - 20);
            dialogE.css({
                left: geometry.x,
                top: geometry.y + funnelHeight
            });

        } else { // 'bottom'
            dialogE.addClass('funnelside-bottom');
            funnelE.css('left', geometry.funnelPlacement - 20);
            dialogE.css({
                left: geometry.x,
                bottom: window.innerHeight - geometry.y2 + funnelHeight
            });
        }

        blockingLayerE.on('touchmove', function (e) {
            blockingLayerE.remove();
            dialogE.remove();
            if (prevFocus) {
                prevFocus.focus();
            }
        });

        blockingLayerE.on('click', function () {
            blockingLayerE.remove();
            dialogE.remove();
            if (prevFocus) {
                prevFocus.focus();
            }
        });

        dialogE.on('keyup', function (e) {
            if (e.keyCode === 27) {
                blockingLayerE.remove();
                dialogE.remove();
                if (prevFocus) {
                    window.setTimeout(function () {
                        prevFocus.focus();
                    }, 100);
                }
            }
        });

        $('body').append(blockingLayerE);
        return dialogE;
    }

    function glossLangMap(lang) {
        var langMap = {
            "no": ["Norsk", "ltr"],
            "nb": ["Norsk", "ltr"],
            "nn": ["Nynorsk", "ltr"],
            "en": ["English", "ltr"],
            "de": ["Deutsch", "ltr"],
            "fr": ["Français", "ltr"],
            "es": ["Español", 'ltr'],
            "fi": ["Suomi", "ltr"],
            "yi": ["ייִדיש", "rtl"],            // Yiddish
            "ar": ["العربية", "rtl"],          // Arabisk
            "prs": ["دری", "rtl"],             // Dari
            "ps": ["پښتو", "rtl"],             // Pashto
            "ti": ["ትግርኛ", "ltr"],            // Tigrinja
            "so": ["Af-Soomaali", "ltr"],      // Somalisk
            "ru": ["русский", "ltr"],          // Russisk
            "uk":  ["украї́нська мо́ва", "ltr"], // Ukrainsk
            "ku":  ["Kurmancî", "ltr"],        // Kurmanji
            "sw":  ["Kiswahili", "ltr"],       // Swahili
            "tr":  ["Türkçe", "ltr"],          // Tyrkisk
            "pl":  ["Polski", "ltr"]           // Polsk
        };
        return langMap[lang] || ["Missing language", "ltr"];
    }

    return {
        showFootnote: function (asideE, subject) {
            var dialog = buildDialogWithFunnel(subject, 'footnoteDialog');
            dialog.html(asideE.html());
            $('body').append(dialog);
        },

        showGlossary: function (url, subject, glossaryLang, userbook, callback) {
            var dialog = buildDialogWithFunnel(subject, 'glossaryDialog');
            prevFocus = subject;

            $.get(url.toString(), function (doc) {
                if (typeof (doc) === "string") {
                    var parser = new DOMParser();
                    doc = parser.parseFromString(doc, "text/html");
                }

                var termTitleElement;
                var multifile = false;
                if (url.fragment()) { // all defs in same file
                    termTitleElement = $(doc).find('#' + url.fragment()); //fragment is the glossary term
                } else { // each def in separate file
                    termTitleElement = $(doc).find('dt');
                    multifile = true;
                }

                $(doc).find('[data-glossary="definition"]').attr('tabindex', '-1');

                var lang, showLangSelect;
                var storedLang = userbook.get('glossaryLang');
                // TODO: hvorfor skjer det at storedLang ikke finnes i glossaryLang?
                if (storedLang && glossaryLang.includes(storedLang)) {
                    lang = storedLang;
                    showLangSelect = false;
                } else {
                    lang = (glossaryLang && glossaryLang.length === 1) ? glossaryLang[0] : null;
                    showLangSelect = glossaryLang && glossaryLang.length > 1;
                }
                var termDescriptionElement;

                // 1. If language is chosen and entry exists, show entry
                // 2. If language not chosen, show fallback entry + dropdown
                // 3. if language chosen but entry doesn't exist, show fallback entry
                // 4. if no fallback exists, show empty
                if (lang) { // multilang glossary book
                    if (multifile && termTitleElement.nextAll('dd[lang="' + lang + '"]:first')[0]) {
                        termDescriptionElement = termTitleElement.nextAll('dd[lang="' + lang + '"]:first');
                        dialog.addClass(glossLangMap(lang)[1]);
                    } else if (termTitleElement.next('dd[lang="' + lang + '"]:first')[0]) {
                        termDescriptionElement = termTitleElement.next('dd[lang="' + lang + '"]:first');
                        dialog.addClass(glossLangMap(lang)[1]);
                    } else {
                        termDescriptionElement = termTitleElement.next('dd[lang="' + glossaryLang[0] + '"]:first') || '';
                        dialog.addClass(glossLangMap(glossaryLang[0])[1]);
                    }
                } else { // single lang glossary book
                    termDescriptionElement = termTitleElement.next('dd:first');
                }

                var dl = $('<dl>', { 'epub:type': 'glossary' });
                dl.append(termTitleElement);
                dl.append(termDescriptionElement);

                dialog.append(dl);
                $('body').append(dialog);

                if (showLangSelect) {
                    var langSelect = $('<select name="langSelect"/>');
                    var option;

                    glossaryLang.forEach(function (glossLang) {
                        var mapLang = glossLangMap(glossLang);
                        option = $('<option>' + mapLang[0] + '</option>');
                        langSelect.append(option);
                    });
                    var chooseBtn = $('<button class="btn-lang">velg</button>');

                    chooseBtn.on('click', function () {
                        var selectedLang = glossaryLang[langSelect[0].selectedIndex];
                        userbook.setGlossaryLang(selectedLang);
                        $('.dialogWithFunnel').detach();
                        $('.invisibleBlockingLayer').detach();
                        if (callback) {
                            callback(true);
                        }
                    });
                    callback(false);

                    dialog.append(langSelect).append(chooseBtn);
                } else {
                    callback(false);
                }
            });
        }
    };

});

