/*global window, localStorage, Offline, cordova, document, navigator, device, define, console, Bugsnag */

/**
 * @module webapp
 * @namespace  Models
 * @class  App
 */


// EVENTS:

/**
 * Triggered when a bookResource is not available on the server (because the server is down or the user is offline but it hasn't been registered by the device yet)
 * @event resourceIsUnavailable
 **/
/**
 * Triggered when the user clicks cancel in logout view
 * @event requestCancelLogout
 **/
/**
 * Triggered in the cordova-app,opens the dialog logut view
 * @event requestLogout
 **/
 /**
 * Triggered when the user is successfully logged in on the vendor system
 * @event loggedInFromVendor
 **/
 /**
  * Triggered when the usersession is lost
  * @event userSessionLost
  */
/** Triggered on changes to downloaded books
 * @event offlineBooks
 */
define('models/App',[
    'jquery',
    'backbone',
    'underscore',
    'collections/Users',
    'models/User',
    'collections/BookResources',
    'models/BookResource',
    'SyncManager',
    'bowser',
    'PlausibleManager',
    'Config',
    'localforage',
    'StorageManager'
], function ($, Backbone, _, Users, User, BookResources, BookResource, SyncManager, bowser, PlausibleManager, clientConfig, localforage, StorageManager) {

    return Backbone.Model.extend({
        defaults: {
            activeUser: null,
            isOnline: null,
            fromReader: false,
            offlineApp: false, // Default: we're not in offline mode
            hasReceived403FromBackend: false,
            activeSessionInfo: null
        },

        initialize: function (options) {
            var me = this;
            var mobileApp = (options && options.mobileApp) || false;
            var backendServerUrl = (options && options.backendServerUrl) || '';

            var offlineApp = false;
            if (bowser.chrome || bowser.safari) {
                offlineApp = true;
            }

            me.set('offlineApp', offlineApp);

            var buildInfo = clientConfig.get('build_info');
            if(window.Bugsnag && buildInfo) {
                window.Bugsnag.appVersion = buildInfo.buildtime + "/" + buildInfo.branchname + "/" + buildInfo.sha;
            }

            this.syncManager = new SyncManager(this);
            this.plausibleManager = new PlausibleManager(this);

            if (me.get('mobileApp')) {
                var isOnline = true;//(navigator && navigator.connection ? (navigator.connection.type === 'none' ? false : true) : false);
                if (navigator && navigator.connection) {
                    isOnline = navigator.connection.type === 'none' ? false : true;
                } else if (window.Offline) {
                    isOnline = Offline.state === "up";
                }
                me.set('isOnline', isOnline);
                document.addEventListener("offline", function () {
                    me.set('isOnline', false);
                }, false);
                document.addEventListener("online", function () {
                    me.set('isOnline', true);
                }, false);

            }
            else {
                if (window.Offline) {
                    Offline.on('confirmed-down', function () {
                        me.set('isOnline', false);
                    });

                    Offline.on('confirmed-up', function () {
                        me.set('isOnline', true);
                    });

                    // explicit trigger a check of state, will either trigger 'confirmed-down' or 'confirmed-up'
                    Offline.check();
                }
                me.storageManager = new StorageManager(me);
            }

            if (me.get('mobileApp')) {
                cordova.getAppVersion(function (version) {
                    me.set('cordovaVersion', version);

                    // Custom header for mobile app
                    $.ajaxSetup({
                        headers: { 'x-unibok-appclient': "OS:" + device.platform + " OS-version:" + device.version + " appVersion:" + version },
                        xhrFields: { withCredentials: true}
                    });
                });
            }

            this.set('mobileApp', mobileApp);
            this.set('backendServerUrl', backendServerUrl);
            this.set('users', new Users());
            this.set('bookResources', new BookResources());

            // if we have received a 403 from the backend and we are not in the process of opening a book (in that case we'll wait), log user out
            this.on('change:hasReceived403FromBackend', function (model, value) {
                var me = this;
                if (value) {
                    if (! me.get('activeUserbook') || ! me.get('activeUserbook').get('isOpening')) {
                        me.trigger('userSessionLost');
                    }
                }
            });

            // a bug (missing feature) in the cordova unzip-plugin forces us to only unpack one book at a time.
            // we use a jquery queue to do this
            this.unpackingQueue = $({});

            // sjekke localstorage
            if (localStorage.getItem('bookResources')) {
                JSON.parse(localStorage.getItem('bookResources')).forEach(function (bookResourceData) {
                    var bookResource = me.getBookResource(bookResourceData, true);
                });
            }

            this.get('bookResources').on('add remove change', this.onDataChanged, this);
        },

        getBookResource: function (bookInfo, autoCreate) {
            var me = this;
            var searchOptions = {
                bookId: bookInfo.bookId,
                publisherRef: bookInfo.publisherRef
            };
            /*if  (bookInfo.epubid) {
                searchOptions.epubid = bookInfo.epubid;
            }*/
            var bookResource = this.get('bookResources').where(searchOptions)[0] || null;

            if (bookResource) {
                bookResource.set(bookInfo);
            }
            if (autoCreate && !bookResource) {
                //download cover
                bookResource = new BookResource(bookInfo, {app: me});
                this.get('bookResources').push(bookResource);
            }
            return bookResource;
        },

        getUser: function (sessionInfo) {
            var user = this.get('users').where({userid: sessionInfo.userid, organizationid: sessionInfo.organizationid})[0] || null;
            if (!user || user !== null) {
                // Create a potential user...
                user = new User(sessionInfo, {app: this});
                this.get('users').push(user);
            }
            return user;
        },

        getUserFromSession: function () {
            var me = this;
            var deferred = $.Deferred();
            var user;
            if (this.get('activeSessionInfo')) {
                user = this.getUser(this.get('activeSessionInfo'));
                deferred.resolve(user);

                // if we open the app with localStorage userdata, check on server
                $.get(me.get('backendServerUrl') + '/api/v1/sessions/info')
                    .done(function (data) {
                        me.set('activeSessionInfo', data);
                    })
                    .fail(function (err) {
                        if (err.status === 403 && err.responseJSON.message === 'Not authorized') {
                            me.trigger('userSessionLost');
                        }
                    });
            } else {
                $.get(me.get('backendServerUrl') + '/api/v1/sessions/info')
                    .done(function (data) {
                        me.set('activeSessionInfo', data);
                        user = me.getUser(me.get('activeSessionInfo'));
                        deferred.resolve(user);
                    })
                    .fail(function (jqxhr) {
                        deferred.reject(jqxhr);
                    });
            }
            return deferred.promise();
        },

        /**
         * Returns the value of the debug-parameter is given, null otherwise.
         * Used to toggle the local content list and the debug button in the reader.
         *
         * @method isDebugMode
         */
        isDebugMode: function () {
            var query = window.location.search;
            if (query && query.length) {
                query = query.substring(1);
            }
            if (query.length) {
                var keyParams = query.split('&');
                for (var x = 0; x < keyParams.length; x++)
                {
                    var keyVal = keyParams[x].split('=');
                    if (keyVal[0] === 'debug' && keyVal.length === 2) {
                        return keyVal[1];
                    }
                }
            }
            return null;
        },

        onDataChanged: function () {
            var bookResourcesData = this.get('bookResources').map(function (bookResource) {
                return bookResource.toJSON();
            });
            localStorage.setItem('bookResources', JSON.stringify(bookResourcesData));
        },

        /**
         * Called when an ajax-request fails
         * @param  {object} err The error object from jquery ajax
         * @param  {funtion} cb Optional callback
         * @return {boolean}     false
         */
        onBackendRequestFail: function (err, cb) {
            var me = this, userbook;
            if (this.get('activeUser')) {
                userbook = this.get('activeUser').get('activeUserbook');
                if (err.status === 403 && err.responseJSON.message === 'Not authorized') {
                    // the downloaded userbook will finish loading anyway, so let's set the flag hasReceived403FromBackend and wait for it to finish loading before we do anything
                    me.set('hasReceived403FromBackend', true);
                }
                else {
                    if (cb) {
                        cb();
                    }
                }
            }
            return false;
        }
    });
});

