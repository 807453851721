/*global window, document, parent, setTimeout */
define('views/LightboxBase',['jquery', 'backbone', 'underscore'], function ($, Backbone, _) {

    var ENTER = 13;
    var ESC = 27;
    var TAB = 9;

    return Backbone.View.extend({
        initialize: function (data) {
            this.lightboxType = data.lightboxType || 'default';

            if (data && data.prevTarget) {
                this._prevTarget = data.prevTarget;
            }

            if (this.lightboxType !== 'imagelightbox') {
                this._blockingLayer = $('<div class="lightbox-blockingLayer"></div>');
                this._blockingLayer.on('touchmove', function (e) { e.preventDefault(); });
                this._blockingLayer.on('click', this._destroy.bind(this));
            }

            this._lightboxPadding_width = 10; // Must correspond to stylesheet.
            this._lightboxPadding_height = 35; // Must correspond to stylesheet.

            this._onResize = _.debounce((function (e) {
                this.updateLayout();
            }).bind(this), 50);
            //this._onResize = this.updateLayout.bind(this);

            // send focus out of the iframe!
            parent.focus();

            _.bindAll(this, '_keydownTriggered');
            $(document).bind('keydown', this._keydownTriggered);
            _.bindAll(this, '_keyupTriggered');
            $(document).bind('keyup', this._keyupTriggered);
        },

        events: {
            'mousedown .resize-handler': 'startResize',
            'mousedown .move-handler': 'startMove',
            'mousemove': 'handleMouseMove',
            'mouseup': 'handleMouseUp',
            'touchstart .resize-handler': 'startResize',
            'touchstart .move-handler': 'startMove',
            'touchmove': 'handleMouseMove',
            'touchend': 'handleMouseUp',
            'click .zoom-in': 'zoomIn',
            'click .zoom-out': 'zoomOut',
            'click .closeButton': '_destroy'
        },

        _keyupTriggered: function (e) {
            switch (e.keyCode) {
                case ENTER:
                    return this.handleEnterKey(e);
                case ESC:
                    return this.handleEscKey(e);
                case TAB:
                    return this.handleTabKey(e);
                default:
                    return this.stopPropagation(e);
            }
        },

        _keydownTriggered: function (e) {
            var $target = $(e.target);
            var step = 10;

            if ($target.hasClass('resize-handler')) {
                switch (e.keyCode) {
                    case 40:  // Down arrow
                    case 39:  // Right arrow
                    case 107: // Plus key
                    case 187: // Plus key (with Shift)
                        this.resizeByKey(step, step);
                        break;
                    case 38:  // Up arrow
                    case 37:  // Left arrow
                    case 109: // Minus key
                    case 189: // Minus key (with Shift)
                        this.resizeByKey(-step, -step);
                        break;
                    default:
                        return this.stopPropagation(e);
                }
                return this.stopPropagation(e);
            } else if ($target.hasClass('move-handler')) {
                switch (e.keyCode) {
                    case 37: // Left arrow
                        this.moveByKey(-step, 0);
                        break;
                    case 38: // Up arrow
                        this.moveByKey(0, -step);
                        break;
                    case 39: // Right arrow
                        this.moveByKey(step, 0);
                        break;
                    case 40: // Down arrow
                        this.moveByKey(0, step);
                        break;
                    default:
                        return;
                }
                return this.stopPropagation(e);
            }
        },

        handleEnterKey: function (e) {
            if ($(e.target).hasClass('closeButton')) {
                this._destroy();
            }
            return this.stopPropagation(e);
        },

        handleEscKey: function (e) {
            this._destroy();
            return this.stopPropagation(e);
        },

        handleTabKey: function (e) {
            var tabbable = this.$el.find('a, button, input, select, textarea, [tabindex]');
            var first = tabbable.filter(':first');
            var last = tabbable.filter(':last');
            var next = tabbable.eq(tabbable.index(e.target) + 1);
            var prev = tabbable.eq(tabbable.index(e.target) - 1);
            if (e.target === last[0] && !e.shiftKey) {
                first.focus();
            } else if (e.target === first[0] && e.shiftKey) {
                last.focus();
            } else if (e.shiftKey) {
                prev.focus();
            } else {
                next.focus();
            }
            return this.stopPropagation(e);
        },

        stopPropagation: function (e) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        },

        requestSize: function (requestedContentWidth, requestedContentHeight, keepAspectRatio, captionHeight) {
            var screenWidth = $('body').width();
            var screenHeight = $('body').height();

            // lightbox margin is 5%
            var marginHeight = (screenHeight / 100) * 5;
            var marginWidth = (screenWidth / 100) * 5;

            if (!captionHeight) {
                captionHeight = 0;
            }

            var maxContentWidth = screenWidth - marginWidth * 2 - this._lightboxPadding_width * 2;
            var maxContentHeight = screenHeight - marginHeight * 2 - this._lightboxPadding_height * 2 - captionHeight;

            this._requestedContentWidth = requestedContentWidth;
            this._requestedContentHeight = requestedContentHeight;
            this._keepAspectRatio = keepAspectRatio;

            requestedContentWidth = requestedContentWidth || maxContentWidth;
            requestedContentHeight = requestedContentHeight || maxContentHeight;


            if (keepAspectRatio && (requestedContentWidth > maxContentWidth || requestedContentHeight > maxContentHeight)) {
                var ratio = requestedContentWidth / requestedContentHeight;
                if (ratio < (maxContentWidth / maxContentHeight)) {
                    this._actualContentWidth = maxContentHeight * ratio;
                    this._actualContentHeight = maxContentHeight;
                } else {
                    this._actualContentWidth = maxContentWidth;
                    this._actualContentHeight = maxContentWidth / ratio;
                }
            } else {
                this._actualContentWidth = Math.min(requestedContentWidth, maxContentWidth);
                this._actualContentHeight = Math.min(requestedContentHeight, maxContentHeight);
            }

        },

        getBookCssPath: function (bookBasePath) {
            // Inject book CSS into lightbox, but publishers have different paths
            var cssFilePath = "CSS/stylesheet.css";
            if (bookBasePath.includes('OEBPS')) {
                cssFilePath = "css/default.css";
            }
            return bookBasePath + cssFilePath;
        },

        show: function () {
            if (this.lightboxType !== 'imagelightbox') {
                $('body').append(this._blockingLayer);
            }
            $('body').append(this.$el);
            this.$el[0].showModal();
            setTimeout(function () {
                $('.closeButton').focus();
            }, 100);
            this.$el.find('.zoom-out').prop('disabled', true);
            this.$el.find('.zoom-in').prop('disabled', false);
            window.addEventListener('resize', this._onResize);
            window.addEventListener('orientationchange', this._onResize);
        },

        startResize: function(e) {
            this.isResizing = true;
            this.startX = e.type.includes('touch') ? e.originalEvent.touches[0].clientX : e.clientX;
            this.startY = e.type.includes('touch') ? e.originalEvent.touches[0].clientY : e.clientY;
            this.startWidth = this.$el.width();
            this.startHeight = this.$el.height();
        },

        startMove: function(e) {
            this.isMoving = true;
            this.lastX = e.type.includes('touch') ? e.originalEvent.touches[0].clientX : e.clientX;
            this.lastY = e.type.includes('touch') ? e.originalEvent.touches[0].clientY : e.clientY;
        },

        handleMouseMove: function(e) {
            if (this.isResizing) {
                this.resize(e);
            } else if (this.isMoving) {
                this.move(e);
            }
        },

        handleMouseUp: function() {
            this.isResizing = false;
            this.isMoving = false;
        },
        

        resize: function (e) {
            if (this.isResizing) {
                var scaleFactor = 2;
                var minWidth = 300;  // Minimum width in pixels
                var minHeight = 300; // Minimum height in pixels

                var clientX = e.type.includes('touch') ? e.originalEvent.touches[0].clientX : e.clientX;
                var clientY = e.type.includes('touch') ? e.originalEvent.touches[0].clientY : e.clientY;

                var deltaX = (clientX - this.startX) * scaleFactor;
                var deltaY = (clientY - this.startY) * scaleFactor;

                var newWidth = Math.max(this.startWidth + deltaX, minWidth);
                var newHeight = Math.max(this.startHeight + deltaY, minHeight);

                this.$el.css({
                    width: newWidth + 'px',
                    height: newHeight + 'px'
                });
            }
        },

        move: function(e) {
            if (this.isMoving) {
                var scaleFactor = 2;

                var clientX = e.type.includes('touch') ? e.originalEvent.touches[0].clientX : e.clientX;
                var clientY = e.type.includes('touch') ? e.originalEvent.touches[0].clientY : e.clientY;

                var deltaX = (clientX - this.lastX) * scaleFactor;
                var deltaY = (clientY - this.lastY) * scaleFactor;

                var currentLeft = parseInt(this.$el.css('left'), 10) || 0;
                var currentTop = parseInt(this.$el.css('top'), 10) || 0;

                this.$el.css({
                    left: (currentLeft + deltaX) + 'px',
                    top: (currentTop + deltaY) + 'px'
                });

                this.lastX = clientX;
                this.lastY = clientY;
            }
        },

        zoomIn: function() {
            var $img = this.$el.find('img');
            if (!this.zoomLevel) {
                this.zoomLevel = 100;
            }

            this.zoomLevel += 50;

            this.zoomLevel = Math.min(this.zoomLevel, 200);

            this.$el.find('figure').css({
                overflow: 'auto'
            }).attr('tabIndex', '0');

            $img.css({
                maxWidth: 'unset',
                maxHeight: 'unset',
                width: this.zoomLevel + '%',
                height: this.zoomLevel + '%',
            });
            if (this.zoomLevel === 150) {
                $img.css({
                    transform: 'translate(-35%)',
                });
            }
            if (this.zoomLevel === 200) {
                $img.css({
                    transform: 'translate(-25%)',
                });
            }
            this.$el.find('.zoom-in').prop('disabled', this.zoomLevel >= 200);
            this.$el.find('.zoom-out').prop('disabled', false);
        },

        zoomOut: function() {
            var $img = this.$el.find('img');

            if (this.zoomLevel) {
                this.zoomLevel -= 50;
            }
            this.zoomLevel = Math.max(this.zoomLevel || 100, 100);

            this.$el.find('figure').css({
                overflow: 'auto'
            });

            $img.css({
                maxWidth: this.zoomLevel === 100 ? '100%' : 'unset',
                maxHeight: this.zoomLevel === 100 ? '95%' : 'unset',
                width: this.zoomLevel + '%',
                height: this.zoomLevel + '%',
            });
            if (this.zoomLevel === 150) {
                $img.css({
                    transform: 'translate(-35%)',
                });
            }
            if (this.zoomLevel === 100) {
                $img.css({
                    transform: 'translate(-50%)',
                });
                this.$el.find('.zoom-out').prop('disabled', true);
                this.$el.find('.zoom-in').prop('disabled', false);
                this.$el.find('figure').css({
                    overflow: 'visible'
                }).removeAttr('tabIndex');
                delete this.zoomLevel;
            }
        },

        moveByKey: function(deltaX, deltaY) {
            var currentLeft = parseInt(this.$el.css('left'), 10) || 0;
            var currentTop = parseInt(this.$el.css('top'), 10) || 0;

            this.$el.css({
                left: (currentLeft + deltaX) + 'px',
                top: (currentTop + deltaY) + 'px'
            });
        },

        resizeByKey: function(deltaWidth, deltaHeight) {
            var currentWidth = this.$el.width();
            var currentHeight = this.$el.height();
            var minWidth = 300;
            var minHeight = 300;

            var newWidth = Math.max(currentWidth + deltaWidth, minWidth);
            var newHeight = Math.max(currentHeight + deltaHeight, minHeight);

            this.$el.css({
                width: newWidth + 'px',
                height: newHeight + 'px'
            });
        },

        _destroy: function () {
            if (this.lightboxType !== 'imagelightbox') {
                this._blockingLayer.detach();
            }
            this.$el[0].close();
            this.$el.detach();
            window.removeEventListener('resize', this._onResize);
            window.removeEventListener('orientationchange', this._onResize);
            $(document).unbind('keydown', this._keydownTriggered);
            $(document).unbind('keyup', this._keyupTriggered);
            if (this._prevTarget) {
                this._prevTarget.focus();
            }
            return false;
        }
    });
});

