/*global window, document, parent, setTimeout */
define('views/LightboxBase',['jquery', 'backbone', 'underscore'], function ($, Backbone, _) {

    var ENTER = 13;
    var ESC = 27;
    var TAB = 9;

    return Backbone.View.extend({
        initialize: function (data) {
            if (data && data.prevTarget) {
                this._prevTarget = data.prevTarget;
            }
            this._blockingLayer = $('<div class="lightbox-blockingLayer"></div>');
            this._blockingLayer.on('touchmove', function (e) { e.preventDefault(); });
            this._blockingLayer.on('click', this._destroy.bind(this));

            this._lightboxPadding_width = 10; // Must correspond to stylesheet.
            this._lightboxPadding_height = 35; // Must correspond to stylesheet.

            this._onResize = _.debounce((function (e) {
                this.updateLayout();
            }).bind(this), 50);
            //this._onResize = this.updateLayout.bind(this);

            // send focus out of the iframe!
            parent.focus();

            _.bindAll(this, '_keydownTriggered');
            $(document).bind('keydown', this._keydownTriggered);
            _.bindAll(this, '_keyupTriggered');
            $(document).bind('keyup', this._keyupTriggered);
        },

        events: {
            'click .closeButton': '_destroy'
        },

        _keyupTriggered: function (e) {
            switch (e.keyCode) {
                case ENTER:
                    return this.handleEnterKey(e);
                case ESC:
                    return this.handleEscKey(e);
                case TAB:
                    return this.handleTabKey(e);
                default:
                    return this.stopPropagation(e);
            }
        },

        _keydownTriggered: function (e) {
            return this.stopPropagation(e);
        },

        handleEnterKey: function (e) {
            if ($(e.target).hasClass('closeButton')) {
                this._destroy();
            }
            return this.stopPropagation(e);
        },

        handleEscKey: function (e) {
            this._destroy();
            return this.stopPropagation(e);
        },

        handleTabKey: function (e) {
            var tabbable = this.$el.find('a, button, input, select, textarea, [tabindex]');
            var first = tabbable.filter(':first');
            var last = tabbable.filter(':last');
            var next = tabbable.eq(tabbable.index(e.target) + 1);
            var prev = tabbable.eq(tabbable.index(e.target) - 1);
            if (e.target === last[0] && !e.shiftKey) {
                first.focus();
            } else if (e.target === first[0] && e.shiftKey) {
                last.focus();
            } else if (e.shiftKey) {
                prev.focus();
            } else {
                next.focus();
            }
            return this.stopPropagation(e);
        },

        stopPropagation: function (e) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        },

        requestSize: function (requestedContentWidth, requestedContentHeight, keepAspectRatio, captionHeight) {
            var screenWidth = $('body').width();
            var screenHeight = $('body').height();

            // lightbox margin is 5%
            var marginHeight = (screenHeight / 100) * 5;
            var marginWidth = (screenWidth / 100) * 5;

            if (!captionHeight) {
                captionHeight = 0;
            }

            var maxContentWidth = screenWidth - marginWidth * 2 - this._lightboxPadding_width * 2;
            var maxContentHeight = screenHeight - marginHeight * 2 - this._lightboxPadding_height * 2 - captionHeight;

            this._requestedContentWidth = requestedContentWidth;
            this._requestedContentHeight = requestedContentHeight;
            this._keepAspectRatio = keepAspectRatio;

            requestedContentWidth = requestedContentWidth || maxContentWidth;
            requestedContentHeight = requestedContentHeight || maxContentHeight;


            if (keepAspectRatio && (requestedContentWidth > maxContentWidth || requestedContentHeight > maxContentHeight)) {
                var ratio = requestedContentWidth / requestedContentHeight;
                if (ratio < (maxContentWidth / maxContentHeight)) {
                    this._actualContentWidth = maxContentHeight * ratio;
                    this._actualContentHeight = maxContentHeight;
                } else {
                    this._actualContentWidth = maxContentWidth;
                    this._actualContentHeight = maxContentWidth / ratio;
                }
            } else {
                this._actualContentWidth = Math.min(requestedContentWidth, maxContentWidth);
                this._actualContentHeight = Math.min(requestedContentHeight, maxContentHeight);
            }

        },

        getBookCssPath: function (bookBasePath) {
            // Inject book CSS into lightbox, but publishers have different paths
            var cssFilePath = "CSS/stylesheet.css";
            if (bookBasePath.includes('OEBPS')) {
                cssFilePath = "css/default.css";
            }
            return bookBasePath + cssFilePath;
        },

        show: function () {
            $('body').append(this._blockingLayer);
            $('body').append(this.$el);
            this.$el[0].showModal();
            setTimeout(function () {
                $('.closeButton').focus();
            }, 100);

            window.addEventListener('resize', this._onResize);
            window.addEventListener('orientationchange', this._onResize);
        },

        _destroy: function () {
            this._blockingLayer.detach();
            this.$el[0].close();
            this.$el.detach();
            window.removeEventListener('resize', this._onResize);
            window.removeEventListener('orientationchange', this._onResize);
            $(document).unbind('keydown', this._keydownTriggered);
            $(document).unbind('keyup', this._keyupTriggered);
            if (this._prevTarget) {
                this._prevTarget.focus();
            }
            return false;
        }
    });
});

