define('templates/browserCheckBox',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (message) {
buf.push("<div class=\"supportedMessage\"><div class=\"header\"><div class=\"logo\"></div><div class=\"separator\"></div><div class=\"unibok\"></div><div class=\"close-btn\"></div></div><div class=\"section\"><h3>" + (jade.escape(null == (jade_interp = message) ? "" : jade_interp)) + "</h3><p>Du bruker en nettleser som ikke gir optimal brukeropplevelse av Unibok.</p><p>Vi anbefaler at du bruker:</p><ul><li>PC: Nyeste versjon av <a href=\"http://www.google.no/intl/no/chrome/\">Chrome</a></li><li>Mac: Nyeste versjon av <a href=\"http://www.google.no/intl/no/chrome/\">Chrome</a> eller nyeste versjon av Safari</li><li>Android- eller Windowsnettbrett: Nyeste versjon av <a href=\"http://www.google.no/intl/no/chrome/\">Chrome</a></li><li>iPad eller iPhone: Prøv vår egen Unibok-app. Du finner den i <a href=\"https://itunes.apple.com/no/app/unibok/id966171664?mt=8\">App Store</a></li></ul><p>Hvis du har spørsmål om din enhet eller operativsystem, ta kontakt med IKT-ansvarlig på din skole.</p><p>Du kan også kontakte oss på følgende e-postadresser:</p><p>E-post: <a href=\"mailto:lokus@aschehoug.no\">lokus@aschehoug.no</a> E-post: <a href=\"mailto:cdukunde@cappelendamm.no\">cdukunde@cappelendamm.no</a></p></div></div>");}.call(this,"message" in locals_for_with?locals_for_with.message:typeof message!=="undefined"?message:undefined));;return buf.join("");
}

});
