/*global document, CSS, console, window, Node, setTimeout, clearTimeout, Image, location, URI, define, ReadiumSDK */

/**
 * @module  webapp
 * @namespace  Views
 * @class  Readerview
 */

define('views/ReaderView',[
    'jquery',
    'backbone',
    'underscore',
    'bowser',
    'async',
    'templates/readerView',
    'views/TopbarView',
    'views/ImageLightboxView',
    'views/TTSEditor',
    'views/OverlayView',
    'views/DialogView',
    'hammer',
    'soundmanager',
    'EnhancementController',
    'SelectionManager',
    'MarginManager',
    'views/NavigationBarView',
    'repairAndFilterXmlString',
    'dialog',
    'ReadiumFactory',
    'HighlightManager',
    'svg4everybody'
], function (
    $,
    Backbone,
    _,
    bowser,
    async,
    mainTemplate,
    TopbarView,
    ImageLightboxView,
    TTSEditor,
    OverlayView,
    DialogView,
    Hammer,
    soundmanager,
    EnhancementController,
    SelectionManager,
    MarginManager,
    NavigationBarView,
    repairAndFilterXmlString,
    dialog,
    ReadiumFactory,
    HighlightManager,
    svg4everybody) {

    function showGoBackButton() {
        $('#goBack-btn').show();
        $('div.progressbar').css('width', $("div#reader").width() - 50);
        $('div.textualIndicators').css('width', $("div#reader").width() - 50);
        $('div.textualIndicators').css('margin-left', '50px');
    }

    function hideGoBackButton() {
        $('#goBack-btn').hide();
        $('div.progressbar').css('width', '100%');
        $('div.textualIndicators').css('width', '100%');
        $('div.textualIndicators').css('margin-left', '0');
    }

    return Backbone.View.extend({
        initialize: function (options) {
            this._currentWidgetViews = [];
            this._lastHighlightSearchResult = null;
            this._focusAfterContentLoad = null;
            this._focusResourceAfterContentLoad = null;
            this._annotationDialogShowing = false;
            this._TTSDialogShowing = false;
            this._currentBookpartId = null;
            this._app = options.app;
            this._appView = options.appView;

            this._topbarTop = (this._hasNotch() && window.orientation === 0) ? true : false;

            this.render();

            this._TTSEditor = new TTSEditor({ readerView: this });
            this._selectionManager = new SelectionManager($.proxy(this.onSelectionChanged, this));
            this._marginManager = new MarginManager(this);
            this.highlightManager = new HighlightManager();
            this.svg4everybody = new svg4everybody();

            // -------------------------
            // Create top and bottom bar
            // -------------------------
            this._topbarView = new TopbarView({
                el: this.$el.find('.topbar'),
                mobileApp: this._app.get('mobileApp') || window.location.search.match(/mobileApp/),
                offlineApp: this._app.get('offlineApp'),
                readerView: this,
                app: this._app,
                getReadium: $.proxy(this.getReadium, this)
            });

            this._navigationBarView = new NavigationBarView({
                el: this.$el.find('.bottombar'),
                onGoto: $.proxy(this._topbarView.onGotoFromToc, this._topbarView),
                mobileApp: this._app.get('mobileApp')
            });

            // ----------------------------------------------------
            // Create proxies (needed for removing event listeners)
            // ----------------------------------------------------

            this._onKeyupProxy = this.onKeyup.bind(this);
        },

        events: {
            'click .skip-to-content': 'skipToContent',
            'click .chapter-btn--prev': 'prevChapter',
            'keyup .chapter-btn--prev': 'prevChapter',
            'click .chapter-btn--next': 'nextChapter',
            'keyup .chapter-btn--next': 'nextChapter',
            'mousedown #annotation-widget-btn': 'preventLosingFocus',
            'mousedown #TTS-widget-btn': 'preventLosingFocus',
            'keyup #enterBookmarkPanel input': 'onKeyUpBookmarkPanel',
            'click #enterBookmarkPanel .btn-create': 'createBookmark',
            'keyup #enterBookmarkPanel .btn-create': 'createBookmark',
            'click #enterBookmarkPanel .btn-remove': 'bookmarkDelete',
            'keyup #enterBookmarkPanel .btn-remove': 'bookmarkDelete',
            'click #enterBookmarkPanel .btn-cancel': 'bookmarkCancelDeletion',
            'keyup #enterBookmarkPanel .btn-cancel': 'bookmarkCancelDeletion',
            'click #enterBookmarkPanel .btn-confirm': 'bookmarkConfirmDeletion',
            'keyup #enterBookmarkPanel .btn-confirm': 'bookmarkConfirmDeletion',

            'click .audioBtn-close': 'closeaudioPlayer',
            'click .audioBtn-collapse': 'toggleaudioPlayer',

            'click': 'toggleMenusClickHandler',
            'click .hideMenus': 'toggleMenus',

            'click #goBack-btn': 'onGoBackButtonClicked',

            'keyup #audioPlayer': 'keyControlPlayer'

        },

        onGoBackButtonClicked: function () {
            try {
                if (this._userbook.get('_stackedReadingPosition')) {
                    var stackPos = JSON.parse(this._userbook.get('_stackedReadingPosition'));
                    this._readium.reader.openSpineItemElementCfi(stackPos.idref, stackPos.contentCFI);
                }
            } catch (e) {
                console.log('No stacked reading position: ', e);
            }
            hideGoBackButton();

            // track back button use with plausible
            this._app.plausibleManager.sendEvent(
                this._userbook.get('publisherRef'),
                'Leseren',
                '"Tilbake"-knapp',
                this._userbook.getBookResource().get('title')
            );
        },

        keyControlPlayer: function (e) {
            if (e.keyCode === 32) {
                soundmanager.keyToggle();
            }
            if (e.keyCode === 39) {
                e.stopPropagation();
                soundmanager.skipForward();
            }
            if (e.keyCode === 37) {
                e.stopPropagation();
                soundmanager.skipBackward();
            }
            if (e.keyCode === 27) {
                this.closeaudioPlayer();
            }
        },

        onKeyUpBookmarkPanel: function (e) {
            if (e.keyCode === 13) {
                this.createBookmark(e);
            }
        },

        render: function () {
            this.setElement(mainTemplate({
                mobileApp: this._app.get('mobileApp')
            }));
            this.$el.addClass('layout-scroll');
            return this;
        },

        toggleMenusClickHandler: function (ev) {
            if (ev.target === this.$el.get(0)) {
                this.toggleMenus();
            }
            return false;
        },

        /**
         * Hack for IE 10 and IE 11 where selection is lost on mousedown.
         */
        preventLosingFocus: function () {
            return false;
        },

        /**
         * Public method for creating an annotation from current selection.
         */
        createAnnotation: function (range, colorid, openAnnotationDialog) {
            range = range || this._selectionManager.getRange();
            if (range) {
                var markedText = range.getText();

                // ================================================
                // sup sub etc in context.
                // Variables that differs from the 'plain text' variants (ie: has some markup) will be assigned a value.
                // ================================================
                var markedTextWithMarkup;
                try {
                    var keepTags = ['em', 'sub', 'sup', 'span', 'svg', 'g', 'line'];
                    markedTextWithMarkup = range.getText(keepTags);

                    if (markedTextWithMarkup === markedText) {
                        markedTextWithMarkup = undefined;
                    }
                } catch (err) {
                    markedTextWithMarkup = undefined;
                }


                colorid = colorid || '1';
                var annotation = this._userbook.createAnnotation(
                    range.idref,
                    range.cfi,
                    colorid,
                    '',
                    markedText,
                    markedTextWithMarkup
                );

                if (openAnnotationDialog) {
                    this._userbook.set('annotationBeingEdited', annotation);
                }
            }
            this._selectionManager.deselect();
        },

        /**
         * Need to confirm deletion
         * todo: remove once topbar dialogs are placed in the topbar DOM
         */
        bookmarkDelete: function (e) {
            if (e.type === "keyup" && e.keyCode !== 13) {
                return;
            }
            return this._topbarView.bookmarkDelete();
        },

        bookmarkConfirmDeletion: function (e) {
            if (e.type === "keyup" && e.keyCode !== 13) {
                return;
            }
            $("#bookmark-btn").focus();
            return this._topbarView.bookmarkConfirmDeletion();
        },

        bookmarkCancelDeletion: function (e) {
            if (e.type === "keyup" && e.keyCode !== 13) {
                return;
            }
            $(".btn-remove").focus();
            return this._topbarView.bookmarkCancelDeletion();
        },

        createBookmark: function (e) {
            if (e.type === "keyup" && e.keyCode !== 13) {
                return;
            }
            var cfi = JSON.parse(this._safeBookmarkCurrentPage());
            var physicalPage = null;

            var bookResource = this._userbook.getBookResource();
            if (cfi && bookResource.hasPhysicalPages()) {
                physicalPage = bookResource.getClosestPagebreak(cfi.idref, cfi.contentCFI);
            }
            var label;
            if (this.$el.find('#enterBookmarkPanel input').val()) {
                label = this.$el.find('#enterBookmarkPanel input').val();
            } else {
                var pagebreak = '#page' + physicalPage;
                var doc = this.$el.find('iframe').contents();

                label = this._getBookmarkLabel(doc, pagebreak);
            }

            if (this.$el.find('#enterBookmarkPanel').data('editing-bookmark')) {
                this._userbook.updateBookmark(this.$el.find('#enterBookmarkPanel').data('editing-bookmark'), label);
            } else {
                this._userbook.createBookmark(cfi.idref, cfi.contentCFI, label, physicalPage);
            }
            // small hack to ensure bookmark-icon goes green even if readium thinks it isn't visible
            $('#reader').addClass('just-createdBookmark');
            this.$el.removeClass('show-enterBookmark-panel');

            $("#bookmark-btn").focus();
            return cfi;
        },

        _getBookmarkLabel: function (doc, pagebreak) {
            // try traversing upwards to find closest header
            var label = doc.find(pagebreak).parent().find(':header:first').text();
            if (!label.length) {
                label = doc.find(pagebreak).parent().parent().find(':header:first')
                    .contents().filter(function () {
                        return this.nodeType === Node.TEXT_NODE;
                    }).text();
            }
            if (!label.length) {
                label = doc.find(pagebreak).parent().parent().find(':header:first').text();
            }
            if (!label.length) {
                label = doc.find(pagebreak).closest('section').find(':header:first').text();
            }
            return label;
        },

        setFocusOnDestinationPagebreak: function () {
            var cfi = JSON.parse(this._safeBookmarkCurrentPage());
            var bookResource = this._userbook.getBookResource();
            if (cfi && bookResource.hasPhysicalPages()) {
                var physicalPage = bookResource.getClosestPagebreak(cfi.idref, cfi.contentCFI);
                var doc = this.$el.find('iframe').contents();
                var pagebreak = doc.find('#page' + physicalPage);
                if (pagebreak.length) {
                    pagebreak.attr('tabindex', '-1')[0].focus();
                }
            }
        },

        dropRedundantPartOfGivenCfi: function (cfi) {
            if (bowser.name === "Safari" || bowser.name === "iPhone" || bowser.name === "iPad" || this._app.get('mobileApp')) {
                if (cfi[cfi.length - 1] !== "]") {
                    cfi = cfi.substring(0, cfi.lastIndexOf("]") + 1);
                }
            }
            return cfi;
        },

        /**
         * Font-related functions
         */
        changeFontsize: function (fontSize) {

            function promisedFontSize(me) {
                var defer = $.Deferred();
                defer.resolve(me._userbook.set('fontSize', fontSize));
                return defer;
            }

            var curCfi = this._readium.reader.bookmarkCurrentPage();
            var cfi = JSON.parse(curCfi).contentCFI;
            var idref = JSON.parse(curCfi).idref;
            if (cfi !== null) {
                cfi = this.dropRedundantPartOfGivenCfi(cfi);
            }

            var me = this;
            promisedFontSize(this).done(function () {
                me._readium.reader.openSpineItemElementCfi(idref, cfi);
            });
            return false;
        },

        // hairy hack to prevent rendering bug on app swipe (EB-1357)
        scrollaPixel: function () {
            var me = this;
            window.setTimeout(function () {
                var scrolledContentFrame = me.$el.find('#scrolled-content-frame'); // should be cached
                scrolledContentFrame.scrollTop(scrolledContentFrame.scrollTop() + 1);
            }, 1);
        },

        skipToContent: function (e) {
            if (e.type === "keyup" && e.keyCode !== 13) {
                return;
            }
            $('#epub-reader-container').focus();
        },

        prevChapter: function (e) {
            if (this._userbook.get('isOpening')) {
                return false;
            }
            if (e.type === "keyup" && e.keyCode !== 13) {
                return;
            }
            if (this._currentBookpartId) {
                var prevSpine = this._userbook.getBookResource().getPreviousSpineItemOnIdref(this._currentBookpartId);
                if (prevSpine) {
                    this._readium.reader.openSpineItemPage(prevSpine.idref, 0, this._readium.reader);
                }
            }
            if (this._app.get('mobileApp')) {
                this.scrollaPixel();
            }
            return false;
        },

        nextChapter: function (e) {
            if (this._userbook.get('isOpening')) {
                return false;
            }
            if (e.type === "keyup" && e.keyCode !== 13) {
                return;
            }
            if (this._currentBookpartId) {
                var nextSpine = this._userbook.getBookResource().getNextSpineItemOnIdref(this._currentBookpartId);
                if (nextSpine) {
                    this._readium.reader.openSpineItemPage(nextSpine.idref, 0, this._readium.reader);
                }
            }
            if (this._app.get('mobileApp')) {
                this.scrollaPixel();
            }
            return false;
        },

        prevPage: function () {
            if (this._userbook.get('isOpening')) {
                return false;
            }
            this._readium.reader.openPageLeft();
            if (this._app.get('mobileApp')) {
                this.scrollaPixel();
            }
            return false;
        },

        nextPage: function () {
            if (this._userbook.get('isOpening')) {
                return false;
            }
            this._readium.reader.openPageRight();
            if (this._app.get('mobileApp')) {
                this.scrollaPixel();
            }
            return false;
        },

        // Events from models

        onFontSizeChanged: function (model, value) {
            $('body').addClass('blocking');
            this._readium.reader.updateSettings({ "fontSize": value });
            this._readium.reader.handleViewportResize();


            // send ga event
            if (value !== this._userbook.previous('fontSize')) {
                // only send plausible event if this value was actually changed
                this._app.plausibleManager.sendEvent(
                    this._userbook.get('publisherRef'),
                    'Leseren',
                    'Endret tekststørrelse',
                    this._userbook.getBookResource().get('title')
                );
            }
        },

        /**
         * Called when userbooks epubid has changed
         * * Either in initializing of userbook model, where it is set to bookresources epubid
         * * In userbooksyncer, where epubid is polled. (not relevant in the mobile app)
         * * In Appview, when the location hash is parsed (not relevant in the mobile app)
         * * Should also be changed from localFileApi, when a new version is downloaded
         * @method onEpubIdChanged
         * @param  {object} userbook
         * @param  {[type]} value    the new epub-id
         */
        onEpubIdChanged: function (bookresource, value) {
            /* bakveggen users can ignore new versions of the epub if in previewMode */
            if (!this._userbook.getUser().get('previewMode')) {
                this._appView.navigateToEpub([
                    encodeURIComponent(bookresource.get('publisherRef')),
                    encodeURIComponent(bookresource.get('bookId')),
                    encodeURIComponent(bookresource.get('epubid'))
                ], { trigger: false });

                var successMessage = new OverlayView({
                    type: 'information',
                    title: 'Oppdatering',
                    text: "En oppdatert versjon av boka er tilgjengelig.",
                    buttonText: "OK",
                    buttonHandler: function () {
                        location.reload(false);
                    },
                    automaticClose: false,
                    manualClose: false
                });
            }
        },

        _safeBookmarkCurrentPage: function () {
            var bookmark;
            try {
                bookmark = this._readium.reader.bookmarkCurrentPage();
            } catch (err) {
                console.log('update reading position failed: ', err);
            }
            return bookmark;
        },

        _hasNotch: function () {
            // try to detect iPhones with 'notch'

            if (bowser.name === "Microsoft Edge" || bowser.name === "Internet Explorer") {
                return false;
            }

            var proceed = false;
            var div = document.createElement('div');
            if (CSS.supports('padding-bottom: env(safe-area-inset-bottom)')) {
                div.style.paddingBottom = 'env(safe-area-inset-bottom)';
                proceed = true;
            } else if (CSS.supports('padding-bottom: constant(safe-area-inset-bottom)')) {
                div.style.paddingBottom = 'constant(safe-area-inset-bottom)';
                proceed = true;
            }
            if (proceed) {
                document.body.appendChild(div);
                var calculatedPadding = parseInt(window.getComputedStyle(div).paddingBottom);
                document.body.removeChild(div);
                if (calculatedPadding > 0) {
                    return true;
                }
            }
            return false;
        },

        _updateLayout: function (noResizeOnReadiumContentElement) {
            // add spinner
            if (!noResizeOnReadiumContentElement) {
                $('body').addClass('blocking');
            }

            if (bowser.ipad &&
                window.innerHeight !== document.documentElement.clientHeight) {
                $('html').height(window.innerHeight);
            }

            // adjust topbar/bottombar position (and scrolled-content-frame if in scroll-mode)
            var bottombar = this.$el.find(".bottombar");
            var topbar = this.$el.find(".topbar");
            var audioWrapper = this.$el.find(".audioWrapper");
            var $window = $(window);
            var scrolledContentFrame = this.$el.find("#scrolled-content-frame");
            this._topbarTop = (this._hasNotch() && window.orientation === 0) ? true : false;

            if ($window.width() < 608) {
                this.$el.find('.topbarHeader').text(this._userbook.getBookResource().get('title'));
                this.$el.find('#locationInfo').text('');
            } else {
                this.$el.find('#locationInfo').text(this._userbook.getBookResource().get('title'));
                this.$el.find('.topbarHeader').text('unibok');
            }

            if (!this._app.get('mobileApp')) {
                if (topbar.offset().top !== 0) {
                    topbar.css("top", (-topbar.height()) + "px");
                    bottombar.css("top", $window.height() + "px");
                    audioWrapper.css("top", ($window.height() - audioWrapper.height()) + "px");
                    audioWrapper.css("left", "0px");

                    if (scrolledContentFrame.length > 0) {
                        this.adjustScrollableContentFrame(0, $window.height());
                    }
                }
                else {
                    topbar.css("top", "0px");
                    bottombar.css("top", ($window.height() - bottombar.height()) + "px");
                    audioWrapper.css("top", ($window.height() - (bottombar.height() + audioWrapper.height())) + "px");
                    audioWrapper.css("left", "0px");

                    if (scrolledContentFrame.length > 0) {
                        this.adjustScrollableContentFrame(topbar.height(), $window.height() - (topbar.height() + bottombar.height()));
                    }
                }
            } else {
                if (this._topbarTop) {
                    topbar.css("height", "75px");
                    topbar.css("top", "0px");
                    bottombar.css("height", "2em");
                    bottombar.css("top", ($window.height() - bottombar.height()) + "px");
                    audioWrapper.css("top", ($window.height() - (bottombar.height() + audioWrapper.height())) + "px");
                } else {
                    topbar.css("top", "0px");
                    topbar.css("height", "50px");
                    bottombar.css("top", ($window.height() - bottombar.height()) + "px");
                    audioWrapper.css("top", ($window.height() - (bottombar.height() + audioWrapper.height())) + "px");
                }
                if (scrolledContentFrame.length > 0) {
                    this.adjustScrollableContentFrame(0, $window.height());
                }
            }


            this._readium.reader.updateSettings({ syntheticSpread: 'single', scroll: 'scroll-doc' });
            this.$el.addClass('layout-scroll');
        },

        _updateReadingPositionCfiAndBookmarkIndicator: _.debounce(function () {
            if (this._userbook) {  // might be null if user clicked goToBookshelf within 400ms
                var readingPos = this._safeBookmarkCurrentPage();
                if (readingPos) {
                    this._userbook.set('readingPositionCfi', readingPos);
                    this._topbarView._updateBookmarkIndicator();
                    window.ravn_hadRealLayoutChange = true;
                }
            }
        }, 400),

        _updateHashWithCurrentPage: _.debounce(function () {
            var readium = this._readium;
            if (!readium || !this._userbook || !this._currentBookpartId) {
                return;
            }

            var curCfi = this._safeBookmarkCurrentPage();
            if (curCfi) {
                curCfi = JSON.parse(curCfi).contentCFI;
            }

            if (!curCfi) {
                return;
            }

            var page = this._userbook.getBookResource().getClosestPagebreak(this._currentBookpartId, curCfi);
            if (!page) {
                return;
            }
            page = parseInt(page); // + 1;
            if (page) {
                var fragment = Backbone.history.getFragment().split('/');

                var mode;
                var publisherIndex = 0;
                var bookIdIndex = 1;
                var epubIdIndex = 2;
                if (fragment[0] === 'bok' || fragment[0] === 'abok' || fragment[0] === 'preview') {
                    mode = fragment[0];
                    publisherIndex = 1;
                    bookIdIndex = 2;
                    epubIdIndex = 3;
                }

                var publisher = fragment[publisherIndex];
                var bookId = fragment[bookIdIndex];
                var epubId = fragment[epubIdIndex];
                this._appView.navigateToEpub([mode, publisher, bookId, epubId, page], { trigger: false, replace: true });
            }
        }, 400),

        onAnnotationAdded: function (model, collection) {
            if (model.get('bookpartId') === this._currentBookpartId) {
                this.highlightManager.colorHighlightAnnotation(model, this._readium);
                this.highlightManager.updateHighlights();
            }
        },

        onAnnotationRemoved: function (model, collection) {
            if (model.get('bookpartId') === this._currentBookpartId) {
                this._readium.reader.plugins.highlights.removeHighlight(model.id);
                this.highlightManager.updateHighlights();
            }
            if (model === this._userbook.get('annotationBeingEdited')) {
                this._userbook.set('annotationBeingEdited', null);
            }
        },

        onAnnotationChanged: function (model) {
            if (model.get('bookpartId') === this._currentBookpartId) {
                var previousId = model.previous('id');
                var doc = $('iframe', this.$el).contents();
                var textAddedOrRemoved = model.hasChanged('text') && !(model.previous('text').length && model.get('text').length);

                if (model.hasChanged('cfi') || model.hasChanged('id') || textAddedOrRemoved) {
                    this._readium.reader.plugins.highlights.removeHighlight(previousId);
                    this.highlightManager.colorHighlightAnnotation(model, this._readium);
                    var id = model.get('id');
                    if (this._userbook.get('annotationBeingEdited')) {
                        doc.find('html > .highlight[data-id="' + id + '"], html > .hover-highlight[data-id="' + id + '"]').addClass('active');
                    }
                } else if (model.hasChanged('colorid') || model.hasChanged('text')) {
                    doc.find('html > [data-type="highlight"][data-id="' + previousId + '"], html > .hover-highlight[data-id="' + previousId + '"]').attr({
                        'data-colorid': model.get('colorid'),
                        'title': model.getText()
                    });
                }

                this.highlightManager.updateHighlights();
            }
        },

        onSelectionChanged: function () {
            if (this._selectionManager.hasSelection() && !this._selectionManager.getError()) {
                if (this._app.get('isOnline')) {
                    // only enable button if online.
                    $('#TTS-widget-btn .icon').addClass('active');
                }

                $('#annotation-widget-btn .icon').addClass('active');
                $('#ordbok-btn .icon').addClass('active');
                var bottom = ($($(".audioWrapper")[0]).css("opacity") > 0) ? '59px' : '20px';
                $('.highlight-button-container').removeClass('hidden');
                $('.highlight-button-container').removeAttr('hidden');
                $(".highlight-button-container").animate({ opacity: 1, bottom: bottom }, 50, () => { });

                this.$el.removeClass('noSelect');
                this.showMenus();
            } else {
                $('#TTS-widget-btn .icon').removeClass('active');

                $('#annotation-widget-btn .icon').removeClass('active');

                $(".highlight-button-container").animate({ bottom: '-70px', opacity: 0 }, 100, () => {
                    $('.highlight-button-container').addClass('hidden');
                    $('.highlight-button-container').attr('hidden', 'hidden');
                });
                if (this.$el.hasClass('noSelect')) {
                    this._TTSEditor.close();
                }
            }
        },

        onAnnotatedAreaClicked: function (annotation) {
            //this._userbook.set('annotationBeingEdited', annotation);
            this._topbarView.onAnnotationBeingEditedChanged(this._userbook, annotation);
            this._clickedAnnotation = true;
            this.$el.removeClass('noSelect');
            this.showMenus();
        },

        focusOnResourceFromResourcePanel: function (cfi) {
            let figureId = '';

            if (this._focusResourceAfterContentLoad) {
                figureId = this._focusResourceAfterContentLoad;
                this._focusResourceAfterContentLoad = null;
            } else {
                // try to exctract resource id from CFI to focus
                let cfiAry = cfi.split('/');
                figureId = cfiAry[cfiAry.length - 2].match(/\[(.*?)\]/)[1];
            }
            // navigerer til nytt kapittel
            if (!this._currentBookpartId) {
                this._focusResourceAfterContentLoad = figureId;
                return;
            }

            var doc = $('iframe', this.$el).contents();
            var widget = doc.find('#' + figureId).find('.eb-widget');
            widget.attr('tabindex', '-1');
            widget.focus();
        },

        focusOnHeaderFromToc: function (href) {
            var sectionId = '';
            var str = href;

            if (this._focusAfterContentLoad) {
                str = this._focusAfterContentLoad;
                this._focusAfterContentLoad = null;
            }

            // navigerer til nytt kapittel
            if (!this._currentBookpartId) {
                this._focusAfterContentLoad = str;
                return;
            }

            // sjekker på kapittel eller seksjon/sidetall
            if (str.indexOf('#') > -1) {
                sectionId = str.split('#')[1];
            } else {
                sectionId = str.slice(str.indexOf('_') + 1, str.indexOf('.'));
            }

            // prøver å finne nærmeste fokuserbare element
            var doc = $('iframe', this.$el).contents();
            let closest = doc.find('#' + sectionId).closest('h3, h2, h1, p'); // søker opp i DOM
            let find = doc.find('#' + sectionId).find('h3, h2, h1, p').first(); // søker ned i DOM

            if (closest.length) {
                closest.attr('tabindex', '-1').focus();
            } else if (find.length) {
                find.attr('tabindex', '-1').focus();
            } else {
                doc.find('h3, h2, h1').first().attr('tabindex', '-1').focus();
            }
        },

        highlightSearchResult: function (idref, cfi) {
            var me = this;
            var elem = null;

            if (this._lastHighlightSearchResult) {
                this.unhighlightLastSearchResult();
            }

            // Only bother to highlight search result if it is in the currently loaded iframe.
            if (this._currentBookpartId === idref) {
                try {
                    elem = this._readium.reader.getElementByCfi(idref, cfi);
                } catch (error) {
                    console.error('highlightResult failed: ', error);
                }

                if (elem) {
                    // highlight search hit paragraph
                    $(elem).addClass('searchHighlight').attr('tabindex', '-1').focus();
                    // highlight search string
                    try {
                        this._readium.reader.plugins.highlights.addHighlight(idref, cfi, 'searchStringHighlightId', "searchStringHighlight");
                    }
                    catch (error) {
                        // Temporary solution for catching readium-errors. This will allow the code below to run regardless of these errors.
                        console.error('READIUM ERROR: ', error);
                    }

                    var timeoutId = setTimeout(function () {
                        me.unhighlightLastSearchResult();
                    }, 5000);

                    this._lastHighlightSearchResult = {
                        idref: idref,
                        cfi: cfi,
                        timeoutId: timeoutId
                    };
                } else {
                    console.log("Highlighting of searchresult: could not find parent element");
                }
            } else { // We store the params in this._lastHighlightSearchResult in order to call this method again when the right document has loaded.
                this._lastHighlightSearchResult = {
                    idref: idref,
                    cfi: cfi,
                };
            }
        },

        unhighlightLastSearchResult: function () {
            if (this._lastHighlightSearchResult) {
                clearTimeout(this._lastHighlightSearchResult.timeoutId);
                this._readium.reader.plugins.highlights.removeHighlight("searchStringHighlightId");
                if (this._lastHighlightSearchResult.idref === this._currentBookpartId) {
                    var doc = this.$el.find('iframe').contents();
                    doc.find('.searchHighlight').removeClass('searchHighlight');
                }
                this._lastHighlightSearchResult = null;
            }
        },

        closeaudioPlayer: function () {
            soundmanager.kill();
            soundmanager.hideAudioWrapper();
            this._TTSEditor.closeAndStop();
            return false;
        },

        toggleaudioPlayer: function () {
            var audioWrapper = $('.audioWrapper');
            var audioPlayer = $('#audioPlayer');
            var animateTime = 300;
            if (!audioWrapper.hasClass('collapse')) {
                audioWrapper.addClass('collapse');

                var newRight = (audioWrapper.width() - 70) + 'px';

                audioPlayer.stop().animate({ 'margin-left': '30px', 'width': '37px' }, animateTime);
                audioWrapper.stop().animate({ 'right': newRight }, animateTime);

                $('.audioBtn-collapse').css('transform', 'rotate(180deg)');
                $('.audioBtn-collapse').attr({ 'aria-label': 'Ekspander lydavspiller', 'aria-expanded': 'false' });
                $('.audioBtn-close').hide();
                $('.audiojs .scrubber').hide();
                $('.audiojs .time').hide();
                $('.speed-controls').hide();
            } else {
                audioWrapper.removeClass('collapse');

                audioPlayer.css('margin-left', 'auto');
                audioPlayer.stop().animate({ 'width': '80vw' }, animateTime);
                audioWrapper.stop().animate({ 'right': '0px' }, animateTime);

                $('.audioBtn-collapse').css('transform', 'rotate(0deg)');
                $('.audioBtn-collapse').attr({ 'aria-label': 'Minimer lydavspiller', 'aria-expanded': 'true' });
                $('.audioBtn-close').show();
                $('.audiojs .scrubber').show();
                $('.audiojs .time').show();
                $('.speed-controls').show();
            }
            return false;
        },

        closeDialogPopups: function () {
            this._annotationDialogShowing = false;
            this._TTSDialogShowing = false;
            this._userbook.set('annotationBeingEdited', null);
            this._selectionManager.deselect();
            this._topbarView.hidePanels();
            this.$el.removeClass('show-bookmarksPanel');
            this.$el.removeClass('show-textlang-panel');
            this.$el.removeClass('show-layout-panel');
            this.$el.removeClass('show-annotationsEditor');
            this.$el.removeClass('show-TTSDialog');
            this._TTSEditor.close();
            // also remove annotation?
            $('.dialogWithFunnel').detach();
            $('.invisibleBlockingLayer').detach();

            $('iframe', this.$el).focus();  // Give access to key navigation
        },

        onKeyup: function (e) {
            // EB-373 Don't navigate in book while editing annotations
            // If key navigation includes arrow up and down, SELECT should
            // also be added.
            if (document.activeElement &&
                (document.activeElement.contentEditable === "true" ||
                    document.activeElement.nodeName === 'TEXTAREA' ||
                    document.activeElement.nodeName === 'INPUT')) {
                if (e.keyCode === 27) {
                    this.closeDialogPopups();
                }

                // No special treatment of the key-event.
            } else {
                var keyboardShortcuts = this._app.get('activeUser').get('keyboardShortcuts');
                if (!keyboardShortcuts && [66, 72, 73, 77, 78, 83].includes(e.keyCode)) {
                    return;
                }
                switch (e.keyCode) {
                    case 9: // tab
                        var cfi = JSON.parse(this._safeBookmarkCurrentPage());
                        var currentPage = null;

                        var bookResource = this._userbook.getBookResource();
                        if (cfi && bookResource.hasPhysicalPages()) {
                            currentPage = parseInt(bookResource.getClosestPagebreak(cfi.idref, cfi.contentCFI));
                        }

                        var lastPageNumber = parseInt(this.$el.find('.lastPageNumber').text());
                        if (currentPage >= lastPageNumber - 1) {
                            $(".chapter-btn--next").removeClass("disabled");
                            _.debounce(function () { $('.chapter-btn--next').css('z-index', 1); }, 1000);
                        }
                        break;
                    case 13: // enter
                        if (e.target.id === "next-page-btn") {
                            this.nextPage();
                        } else if (e.target.id === "previous-page-btn") {
                            this.prevPage();
                        } else if ($(e.target).attr("class") === "play-pause") {
                            if ($("#audiojs_wrapper0").hasClass("playing")) {
                                soundmanager.kill();
                            }
                            else {
                                soundmanager.play();
                            }
                        } else if ($(e.target).attr("class") === "audioBtn-close") {
                            this.closeaudioPlayer();
                        } else if ($(e.target).attr("class") === "audioBtn-collapse") {

                            this.toggleaudioPlayer();
                        }
                        break;
                    case 32: // space
                        this.closeDialogPopups();
                        this.nextPage();
                        break;
                    case 38: // up
                    case 40: // down
                        break;
                    case 37: // left
                        if (!this._topbarView.getActivePanel()) {
                            this.closeDialogPopups();
                            this.prevPage();
                        }
                        break;
                    case 39: // right
                        if (!this._topbarView.getActivePanel()) {
                            this.closeDialogPopups();
                            this.nextPage();
                        }
                        break;
                    case 66: // b
                        if (e.shiftKey) {
                            this._topbarView.openBookmarksPanel(e);
                        } else {
                            this._topbarView.onBookmarkButtonClicked(e);
                        }
                        break;
                    case 83: // s
                        this._topbarView.openSearchPanel(e);
                        break;
                    case 72: // h
                        this._topbarView.gotoBookshelf();
                        break;
                    case 73: // i
                        this._topbarView.openTocPanel(e);
                        break;
                    case 77: // m
                        this.toggleMenus();
                        break;
                    case 78: // N
                        this._topbarView.openAnnotationsPanel(e);
                        break;
                    case 117: // F6
                        e.preventDefault();

                        var bookshelf_btn = $("#goto-bookshelf-btn");
                        var iframe_contents = $("iframe").contents();

                        if (bookshelf_btn.is(":focus")) {
                            var focus_back = iframe_contents.find(".latest_focused_element")[0];
                            $(focus_back).focus();
                            $(focus_back).removeClass("latest_focused_element");
                            this.hideMenus();

                        } else {
                            var focused_el = iframe_contents[0].activeElement;
                            $(focused_el).addClass("latest_focused_element");
                            bookshelf_btn.focus();
                            this.showMenus();
                        }
                        break;
                    case 27: // escape
                        if ($(e.target).attr("class") === "play-pause") {
                            if ($("#audiojs_wrapper0").hasClass("playing")) {
                                soundmanager.kill();
                            }
                        }
                        else {
                            this.closeDialogPopups();
                        }
                        break;
                }
            }
        },

        contentDocumentPreprocess: function (iframe, s, mainCallback) {
            var me = this;
            iframe.attr({ 'title': 'bokinnhold ' + this._userbook.getBookResource().get('title'), 'width': '1000', 'height': '1000' });
            this._marginManager.cleanUpOldAndCreateNewMargins();
            this.enhancementController = new EnhancementController(iframe, me._userbook, me._app);
            async.parallel([
                function (asyncDoneCallback) {
                    if (!me._userbook) { return false; }
                    me._userbook.getBookResource().getIframeContentCssUrl(function (cssUrl) {
                        var style = document.createElement('link');
                        style.rel = 'stylesheet';
                        style.type = 'text/css';
                        style.href = cssUrl;
                        iframe.contents().find('head').append(style);
                        asyncDoneCallback(null);
                    });
                },
                function (asyncDoneCallback) {
                    if (!me._userbook) { return false; }
                    me.enhancementController.postprocessMath(asyncDoneCallback);
                },
                function (asyncDoneCallback) {
                    if (!me._userbook) { return false; }
                    me.enhancementController.initWidgets(s, asyncDoneCallback);
                }
            ], function () {
                me._widgetsReady = true;
                mainCallback(null);
            });
        },

        onReadiumContentDocumentLoaded: function (iframe, s) {
            var me = this;
            var readium = this._readium;
            window.ravn_hadRealLayoutChange = true;
            this._currentBookpartId = s.idref;
            var idoc = iframe.contents()[0];
            this._selectionManager.setDocument(idoc, this._userbook.getBookResource(), this._currentBookpartId);
            var epubHtml = iframe.contents().find('html');

            // -----------------------------
            // Initializing other 'features'
            // -----------------------------

            this.highlightManager.registerOnMouseMoveEventHandler();

            this._TTSEditor.init();

            // prevent toggleMenus for some elements
            epubHtml.on('click', 'summary, details', function (e) {
                e.stopPropagation();
                return true;
            });

            epubHtml.find('[epub\\:type="noteref"]').each(function () { $(this).off(); });
            epubHtml.on('click', '[epub\\:type="noteref"]', function () {
                var subject = this;
                var href = $(this).attr('href');
                dialog.showFootnote(epubHtml.find('aside[epub\\:type="footnote"]' + href), subject);
                return false;
            });

            epubHtml.find('.mjx-chtml').each(function () {
                /* EB-1832, selection must extend to the full math-expression
                 * HACK: At this moment, start and endpoints of selections/ranges must be in textnodes.
                 * If mathexpression is at the beginning or end in element, we need to insert empty textnodes before/after
                 */
                if (!this.previousSibling) {
                    this.parentNode.insertBefore(document.createTextNode(''), this);
                }
                if (!this.nextSibling) {
                    this.parentNode.appendChild(document.createTextNode(''));
                }
            });

            epubHtml.find('[data-glossary="primary"]').each(function () { $(this).off(); });
            epubHtml.on('click', '[data-glossary="primary"]', function () {
                var subject = this;

                var chapterPath = '';
                if (s.href.indexOf('/') > -1) {
                    chapterPath = s.href.substring(0, s.href.lastIndexOf('/') + 1);
                }
                //Extract fragment (ie. the glossary term) to support blobification
                var uri = new URI($(subject).attr('href')).absoluteTo(me._userbook.getBookResource().getBaseUrl() + chapterPath);
                var glossaryTerm = uri.fragment();
                var glossaryLang = me._userbook.getBookResource().getGlossaryLang();
                me._userbook.getBookResource().getResourceUrl(uri.fragment('').toString(), function (err, resourceUrl) {
                    dialog.showGlossary(new URI(resourceUrl).fragment(glossaryTerm), subject, glossaryLang, me._userbook, function (reopenDialog) {
                        if (reopenDialog) {
                            // reopen dialog with chosen language
                            dialog.showGlossary(new URI(resourceUrl).fragment(glossaryTerm), subject, glossaryLang, me._userbook, function (reopenDialog) {
                                $('.glossaryDialog').find('[data-glossary="definition"]')[0].focus();
                            });
                        } else {
                            $('.glossaryDialog').find('[data-glossary="definition"]')[0].focus();
                        }
                    });
                });

                me._app.plausibleManager.sendEvent(
                    me._userbook.get('publisherRef'),
                    'Leseren',
                    'Ordoppslag',
                    me._userbook.getBookResource().get('title')
                ); return false;

            });

            epubHtml.find('aside[epub\\:type="footnote"]').hide();
            epubHtml.find('a[href^="http:"]').addClass('external-link').attr('target', '_blank');

            // unbind internal links that aren't glossary, noteref or footnotes.. does this cover everything?
            // TODO : check what Readium does with a-tags
            epubHtml.find('a:not([href^="http"]):not([data-glossary="primary"]):not([epub\\:type="noteref"]):not([epub\\:type="footnote"])').each(function () {
                $(this).unbind();
            });
            epubHtml.on('click', 'a:not([href^="http"]):not([data-glossary="primary"]):not([epub\\:type="noteref"]):not([epub\\:type="footnote"])', function () {
                var href = $(this).attr('href');
                me._userbook.set('_stackedReadingPosition', me._userbook.get('readingPositionCfi'));
                showGoBackButton();
                readium.reader.openContentUrl(href, s.href);
                me.setFocusOnDestinationPagebreak();
                return false;
            });

            epubHtml.find('img:not([data-noscale])').attr({ 'tabindex': '0' });
            epubHtml.on('click keyup', 'img:not([data-noscale])', function (e) {
                if (e.keyCode && e.keyCode !== 13) { return; }
                var imageurl = this.src;
                var title = this.title;
                /* We use the text nodes in the parent element as caption. Remove all html tags except the ones we specifically whitelist */
                var caption = $(this).parent('figure').find('figcaption').html();
                var alt = $(this).attr('alt');
                var width = this.naturalWidth || $(this).width();
                var height = this.naturalHeight || $(this).height();
                var bookResource = me._userbook.getBookResource();

                new ImageLightboxView({
                    bookResource: bookResource,
                    imageurl: imageurl,
                    title: title,
                    caption: caption,
                    alt: alt,
                    imageWidth: width,
                    imageHeight: height,
                    prevTarget: e.target
                });

                return false;
            });

            // click event for print icon Helsedir
            epubHtml.on('click', '.unibokprintbutton', function () {
                if (this.hasAttribute('id') && $(this).closest('unibokprint')) {
                    me._userbook.set('printerIconClicked', {
                        'section_id': this.parentElement.getAttribute('id')
                    });
                }
            });

            // --------------------------------------------------------
            // Compensating for bug in readium reachStableContentHeight
            // (or bug in some browsers), see EB-636
            // We first check after 100 ms, and then each 1000 ms
            // --------------------------------------------------------

            if (this._checkDocumentHeight_timerId) {
                window.clearInterval(this._checkDocumentHeight_timerId);
                this._checkDocumentHeight_timerId = null;
            }

            function adjustIframeSizeIfNeeded() {
                var win = iframe[0].contentWindow;
                var doc = iframe[0].contentDocument;
                if (win && doc) { // Window can be null if iframe is detached. Can happen when we go to another chapter.
                    var iframeHeight = parseInt(Math.round(parseFloat(window.getComputedStyle(iframe[0]).height)));
                    var docHeight = $(doc).outerHeight();
                    var scalerHeight = $('#scaler').height();

                    var diff = iframeHeight - docHeight;
                    if (Math.abs(diff) > 4) {
                        console.warn('Iframe content height changed after it was supposed to be stable!');
                        readium.reader.handleViewportResize();
                    } else if (scalerHeight !== iframeHeight) {
                        //-----------------------------------------------------------------------------------------
                        // Cause of EB-1160, the elements wrapping the readium iframe do not get the correct height
                        // for some reason. Compensating for that setting the height for the scaler and the content-doc-frame elements.
                        // -----------------------------------------------------------------------------------------
                        me.$el.find('#scaler').height(iframeHeight);
                        me.$el.find('.content-doc-frame').height(iframeHeight);
                    }
                }
            }

            window.setTimeout(adjustIframeSizeIfNeeded, 100);
            this._checkDocumentHeight_timerId = window.setInterval(adjustIframeSizeIfNeeded, 1000);

            // focus from Toc when new chapter is loaded
            if (this._focusAfterContentLoad) {
                this.focusOnHeaderFromToc();
            } else if (this._focusResourceAfterContentLoad) {
                this.focusOnResourceFromResourcePanel();
            }

            // highlight last search result when changing chapters
            if (this._lastHighlightSearchResult) {
                this.highlightSearchResult(this._lastHighlightSearchResult.idref, this._lastHighlightSearchResult.cfi);
            }

            me._userbook.set('isOpening', false);

        },

        onReadiumPaginationChanged: function (pageChangeData) {
            var me = this;

            if (this._currentBookpartId === undefined) {
                return; // For some strange reason, we got here before CONTENT_DOCUMENT_LOADED
            }

            if (!this._userbook) {
                // In some cases, we are called after ReaderView is no longer open, and
                // this._userbook has been set to null
                return;
            }

            if (!this._widgetsReady) {
                // We need to call this method again as soon as the widgets have been rendered.
                // We solve this in a simple (but perhaps not so elegant) way, by saving the pageChangeData,
                // and calling this method again explicitly when all widgets are ready.
                this._lastPageChangeData = pageChangeData;
                return;
            }
            if (!pageChangeData) {
                // This is our lazy solution for making pageChangeData available when this method is not triggered from within Readium.
                pageChangeData = this._lastPageChangeData;
            }

            this._marginManager._pageChangeData = pageChangeData;

            this._updateReadingPositionCfiAndBookmarkIndicator();
            this._updateHashWithCurrentPage();

            var doc = this.$el.find('iframe').contents();

            if (window.ravn_hadRealLayoutChange) {

                // close open glossary dialog
                $('.glossaryDialog').detach();
                $('.invisibleBlockingLayer').detach();

                // repositioning annotations
                // This is a horrible way to 'update' the markings. It can be quite slow. We must find a better way.
                // Remove old highlights and indicators, this seems to improve rendering a lot on tablets
                doc.find('.highlight[data-type=highlight]').remove();

                // making sure new highlights are added after the chapter has been rendered (JIRA: EB-980) by putting it in a timeout

                setTimeout(
                    function () {
                        // make sure userbook is still defined when function is executed (cause of setTimeout)
                        if (me._userbook) {
                            var id;
                            if (me._currentBookpartId) {
                                me._userbook.get('annotations').forEach(function (annotation) {
                                    if (annotation.get('bookpartId') === me._currentBookpartId) {
                                        me._readium.reader.plugins.highlights.removeHighlight(annotation.id);
                                        me.highlightManager.colorHighlightAnnotation(annotation, me._readium);
                                    }
                                });
                                if (me._userbook.get('annotationBeingEdited')) {
                                    id = me._userbook.get('annotationBeingEdited').get('id');
                                    doc.find('html > .highlight[data-id="' + id + '"], html > .hover-highlight[data-id="' + id + '"]').removeClass('justJumpedTo').addClass('active');

                                }
                                if (me._annotationJustJumpedTo) {
                                    id = me._annotationJustJumpedTo.get('id');
                                    doc.find('html > .highlight[data-id="' + id + '"], html > .hover-highlight[data-id="' + id + '"]').addClass('justJumpedTo');
                                }
                                // reposition annotationIndicators and page numbers
                                me._marginManager.onLayoutUpdate(me._currentBookpartId);
                                // uncomment this when it's ready
                                me.highlightManager.updateHighlights();

                                $('body').removeClass('blocking');
                                $('body').find('.splashscreen').detach();

                                if (me._lastHighlightSearchResult) {
                                    me._readium.reader.plugins.highlights.removeHighlight('searchStringHighlightId');
                                    me._readium.reader.plugins.highlights.addHighlight(
                                        me._lastHighlightSearchResult.idref,
                                        me._lastHighlightSearchResult.cfi,
                                        'searchStringHighlightId',
                                        'searchStringHighlight'
                                    );
                                    me._readium.reader.openSpineItemElementCfi(me._lastHighlightSearchResult.idref, me._lastHighlightSearchResult.cfi);
                                }
                            }
                        }
                    },
                    1
                );
                window.ravn_hadRealLayoutChange = false;
            } else {
                // No real layout changed.

                // me._readium.reader.plugins.highlights.redrawAnnotations();
                me._userbook.get('annotations').forEach(function (annotation) {
                    if (annotation.get('bookpartId') === me._currentBookpartId) {
                        doc.find('html > .highlight[data-id="' + annotation.attributes.id + '"], html > .hover-highlight[data-id="' + annotation.attributes.id + '"]').attr('data-colorid', annotation.attributes.colorid);
                    }
                });

                if (me._annotationJustJumpedTo) {
                    var id = me._annotationJustJumpedTo.get('id');
                    doc.find('html > .highlight[data-id="' + id + '"], html > .hover-highlight[data-id="' + id + '"]').addClass('justJumpedTo');
                }

                $('#reader').removeClass('just-createdBookmark');
            }

            // On initialization pageChangeData is undefined. Not sure if this should be handled
            // here or in _updateNavigationBar. TODO: Check with kaa.
            if (pageChangeData) {
                //this._updateNavigationBar(pageChangeData);
                this._navigationBarView.update(pageChangeData, this._currentBookpartId, this._userbook.getBookResource(), this._readium);

                //--------------------------
                // Update navigation arrows
                // Enable/disable
                // TODO: move this to somewhere else.
                //--------------------------

                var pinfo = pageChangeData.paginationInfo;
                var pageLeft = pinfo.openPages[0];


                /**********************/
                // trying to figure out EB-1840
                if (!pageLeft && window.Bugsnag) {
                    var message = 'Pageleft is not defined. ';
                    if (!pinfo) {
                        message += 'Pinfo is not defined either';
                    } else {
                        message += 'Pinfo is defined, but openPages[0] does not exist';
                    }

                    window.Bugsnag.notify(new Error(message), function (event) {
                        event.errors[0].errorClass = 'EB-1840';
                        event.severity = 'warning';
                    });
                }
                /***********************/

                if (!me._userbook.getBookResource().getPreviousSpineItemOnIdref(me._currentBookpartId)) {
                    this.$el.find('.chapter-btn--prev').addClass('disabled');
                    _.debounce(function () { me.$el.find('.chapter-btn--prev').css('z-index', 0); }, 1000);
                } else {
                    this.$el.find('.chapter-btn--prev').removeClass('disabled');
                    this.$el.find('.chapter-btn--prev').css('z-index', 1);
                }
                if (!me._userbook.getBookResource().getNextSpineItemOnIdref(me._currentBookpartId)) {
                    this.$el.find('.chapter-btn--next').addClass('disabled');
                    _.debounce(function () { me.$el.find('.chapter-btn--next').css('z-index', 0); }, 1000);
                } else {
                    this.$el.find('.chapter-btn--next').removeClass('disabled');
                    this.$el.find('.chapter-btn--next').css('z-index', 1);
                }
                /* Hiding the previous and next buttons if we're not on the first or last page */
                if (pageLeft.spineItemPageIndex > 0) {
                    $('.chapter-navigation--top').children().addClass('disabled');
                    _.debounce(function () { $('.chapter-navigation--top').children().css('z-index', 0); }, 1000);
                }
                if (pageLeft.spineItemPageIndex !== (pageLeft.spineItemPageCount - 1)) {
                    $('.chapter-navigation--bottom').children().addClass('disabled');
                    _.debounce(function () { $('.chapter-navigation--bottom').children().css('z-index', 0); }, 1000);

                }
            }
        },

        toggleMenus: function (event) {
            if (event) {
                var id = event.target.getAttribute('data-id');
                if (id) {
                    var annotation = this._userbook.get('annotations').where({ id: id })[0];
                    this.onAnnotatedAreaClicked(annotation);
                    return annotation;
                }
            }

            var topbar = this.$el.find(".topbar");

            if (topbar.offset().top === 0) {
                this.hideMenus();
            }
            else {
                this.showMenus();
            }
        },

        showMenus: function () {
            var me = this;

            var topbar = me.$el.find(".topbar");
            if (topbar.position().top === 0) {
                return;
            }

            var scrolledContentFrame = me.$el.find('#scrolled-content-frame');
            var bottombar = me.$el.find(".bottombar");
            var audioWrapper = me.$el.find(".audioWrapper");
            var $window = $(window);

            var currentScrollTopPosition = scrolledContentFrame.scrollTop();
            var newScrollTopPosition = currentScrollTopPosition + topbar.height();

            me.$el.removeClass('hideMenus');
            $('.topbarHeader').removeClass('hideMenus');

            bottombar.stop().animate({ "top": ($window.height() - bottombar.height()) + "px" }, 300);
            audioWrapper.stop().animate({ "top": ($window.height() - bottombar.height() - audioWrapper.height()) + "px" }, 300);

            var topbarHeight = this._topbarTop ? 75 : 50;
            topbar.css('height', topbarHeight + 'px');
            topbar.stop().animate({ "top": "0px" }, {
                duration: 300, complete: function () {
                    me.adjustScrollableContentFrame(topbar.height(), $window.height() - (bottombar.height() + topbar.height()), newScrollTopPosition);
                }
            });

            if ($($(".audioWrapper")[0]).css("display") !== "none") {
                $("#goBack-btn").animate({ 'bottom': '59px' }, 500, function () { });
            }
            else {
                $("#goBack-btn").animate({ 'bottom': '25px' }, 500, function () { });
            }
        },

        hideMenus: function () {
            var me = this;

            var topbar = me.$el.find(".topbar");
            if (topbar.position().top !== 0) {
                return;
            }

            var scrolledContentFrame = me.$el.find('#scrolled-content-frame');
            var bottombar = me.$el.find(".bottombar");
            var audioWrapper = me.$el.find(".audioWrapper");
            var $window = $(window);

            var currentScrollTopPosition = scrolledContentFrame.scrollTop();
            var newScrollTopPosition = currentScrollTopPosition - topbar.height();

            var scrollPositionTopOrBottom = (((currentScrollTopPosition + 1) < topbar.height()) ||
                (
                    ((scrolledContentFrame.prop("scrollHeight") - scrolledContentFrame.scrollTop()) -
                        scrolledContentFrame.height()) < bottombar.height()
                )
            );

            me.$el.addClass('hideMenus');
            this.closeDialogPopups();

            if (scrollPositionTopOrBottom) {
                scrolledContentFrame.stop().animate({ "top": "0px", "height": $window.height() + "px" }, 300);
            }
            else {
                me.adjustScrollableContentFrame(0, $window.height(), newScrollTopPosition);
            }
            topbar.stop().animate({ "top": - topbar.height() - 1 + "px" }, 300);
            bottombar.stop().animate({ "top": ($window.height() + bottombar.height()) + "px" }, 300);
            audioWrapper.stop().animate({ "top": ($window.height() - audioWrapper.height()) + "px" }, 300, function () {
                $('.topbarHeader').addClass('hideMenus');
            });

            if ($($(".audioWrapper")[0]).css("display") !== "none") {
                $("#goBack-btn").animate({ 'bottom': '79px' }, 500, function () { });
            }
            else {
                $("#goBack-btn").animate({ 'bottom': '44px' }, 500, function () { });
            }

        },

        adjustScrollableContentFrame: function (top, height, scrollTop) {
            var scrolledContentFrame = this.$el.find('#scrolled-content-frame');
            scrolledContentFrame.css("top", top + "px");
            scrolledContentFrame.height(height);
            if (scrollTop) {
                scrolledContentFrame.scrollTop(scrollTop);
            }
        },

        enableSwipeGesture: function (documentElement) {
            var me = this;

            var mc = new Hammer.Manager(documentElement, {
                cssProps: {} // We prevent default cssProps to avoid possible side-effects. At least we need to avoid cssProps.userSelect
            });
            mc.add(new Hammer.Swipe({ direction: Hammer.DIRECTION_HORIZONTAL }));
            mc.on('swipeleft', function (e) {
                me.nextPage();
            });
            mc.on('swiperight', function (e) {
                me.prevPage();
            });
        },

        getReadium: function () {
            if (!this._readium) {
                this.createReadium();
            }

            return this._readium;
        },

        createReadium: function () {
            var readiumFactory = new ReadiumFactory(this);
            this._readium = readiumFactory.createReadium();
        },

        enter: function (userbook) {
            this.hasLeftView = false;
            this.createReadium(); //see EB-1588 for details
            var me = this;
            this._userbook = userbook;
            userbook.getBookResource().on('change:downloadProgress', this.onDownloadProgressChanged, this);
            userbook.getBookResource().on('change:downloadedEpubid', me.onDownloadFinished, me);
            var bookResource = userbook.getBookResource();

            userbook.validateUserdata();

            this.$el.addClass('active');

            this.$el.addClass(this._userbook.get('cloudStatus'));
            me._userbook.on('change:cloudStatus', me.changedCloudStatus, this);

            // send plausible to publisher of book
            this._app.plausibleManager.sendPageView(bookResource.get('publisherRef'), '/' + bookResource.get('bookId'));

            var readingPositionCfi = userbook.get('readingPositionCfi');
            var openPageRequest = null;
            if (readingPositionCfi) {
                var bookmark = JSON.parse(readingPositionCfi);
                if (bookmark.idref && bookResource._spine.checkIdrefExists(bookmark.idref)) {
                    openPageRequest = { idref: bookmark.idref, elementCfi: bookmark.contentCFI };
                }

            }

            this._readium = this.getReadium();
            this._readium.reader.resetCurrentView();

            // ------------------------------
            // Other stuff...
            // ------------------------------

            this._topbarView.enter(userbook);
            this.closeDialogPopups();

            // -------------------------------------------------------------
            // Registers callbacks for changes in the current userbook model
            // -------------------------------------------------------------
            userbook.on('change:fontSize', this.onFontSizeChanged, this);
            userbook.getBookResource().on('change:epubid', this.onEpubIdChanged, this);
            userbook.on('usersessionlost', this._appView.onUserSessionLost, this._appView);

            userbook.get('annotations').on('add', this.onAnnotationAdded, this);
            userbook.get('annotations').on('remove', this.onAnnotationRemoved, this);
            userbook.get('annotations').on('change', this.onAnnotationChanged, this);

            this._marginManager.startListeningOnUserbook(userbook);

            // -----------------------------------------------------------------------------
            // Updates view. (Further updates are triggered by listening to backbone events)
            // -----------------------------------------------------------------------------

            this.onFontSizeChanged(userbook, userbook.get('fontSize'));
            this._updateLayout();

            // -----------------------------
            // Registers keyboard navigation
            // -----------------------------

            $(window).on('keyup', this._onKeyupProxy);
            this._readium.reader.addIFrameEventListener('keyup', this._onKeyupProxy);

            // ----------------------------------------------------------------
            // Kicks Readium into action!
            // This will trigger CONTENT_DOCUMENT_LOADED and PAGINATION_CHANGED
            // ----------------------------------------------------------------
            bookResource.loadPackageDocument(me._readium, function (packageDocument) {
                var topbarHeight = me.$el.find(".topbar").height();
                var bottombarHeight = me.$el.find(".bottombar").height();
                var audioWrapper = me.$el.find(".audioWrapper");

                if (me.$el.find(".topbar").offset().top === 0) {
                    // if menus are visible adjust scrolledContentFrame to correct position and height.
                    me.adjustScrollableContentFrame(topbarHeight, ($(window).height() - (bottombarHeight + topbarHeight)));
                    audioWrapper.css("top", $(window).height() - (bottombarHeight + audioWrapper.height()));
                }
                else {
                    me.adjustScrollableContentFrame(0, $(window).height());
                    audioWrapper.css("top", $(window).height() - audioWrapper.height());
                }
                me._readium.reader.once(ReadiumSDK.Events.CONTENT_DOCUMENT_LOADED, function (iframe, s) {
                    setTimeout(function () { bookResource._spine.getSpineItems(); }, 1000);
                });

                // accessibility
                me._appView.ariaLiveRegion.html('');
                $('#reader').focus();
                window.clearInterval(window.liveRegionUpdater);
                window.liveRegionUpdater = undefined;

            }, openPageRequest);
        },

        leave: function () {
            this.$el.removeClass('active');

            if (!(this._app.get('mobileApp') || bowser.ios || bowser.safari)) { // failed on some version of iOS, EB-2767
                if (this.$el.find('iframe').contents().find('body')[0] &&
                    this.$el.find('iframe').contents().find('body')[0].resizeSensor) {
                    this.$el.find('iframe').contents().find('body')[0].resizeSensor.detach(); // fant ikke noen måte å forlate readium-instansen på i deres API
                }
            }
            $(window).off('keyup', this._onKeyupProxy);
            $(window).off("resize.ReadiumSDK.readerView"); //EB-1006, caused error in bookshelfview after leaving readerview

            // if(this._appView.previewMode) {
            if (this._userbook.getUser().get('previewMode')) {
                this._userbook.getUser().unset('previewMode', { silent: true });
                this._userbook.getUser().trigger('requestUpdate_bookshelf');
            }

            if (this._checkDocumentHeight_timerId) { // NOTE: It would be better if this code is triggered when unloading a chapter. But we have no such event atm.
                window.clearInterval(this._checkDocumentHeight_timerId);
                this._checkDocumentHeight_timerId = null;
            }

            this.closeaudioPlayer();

            this._marginManager.stopListeningOnUserbook();

            this.$el.find('iframe').contents().find('video').trigger('pause'); // for Safari to stop playback

            this.$el.find('#epub-reader-container').empty();
            this.$el.removeClass(this._userbook.get('cloudStatus'));

            this._userbook.off(null, null, this);
            this._userbook.get('annotations').off(null, null, this);
            this._userbook.getBookResource().off(null, null, this);
            this._userbook.getUser().off(null, null, this);
            this._userbook = null;
            this.hasLeftView = true;
            this._topbarView.leave();
        },

        abortDownload: function (e) {
            var userbook = this._userbook;
            new DialogView({
                'isConfirm': true,
                'title': 'Advarsel',
                'message': 'Vil du avbryte prosessen med å gjøre boka tilgjengelig frakoblet?',
                'onConfirm': function () { userbook.getBookResource().abortDownload(); }
            });
        },

        changedCloudStatus: function () {
            var previousCloudStatus = this._userbook.previous('cloudStatus');
            if (previousCloudStatus) {
                this.$el.removeClass(previousCloudStatus);
            }
            this.$el.addClass(this._userbook.get('cloudStatus'));
        },

        onDownloadProgressChanged: function () {
            var progress = this._userbook.getBookResource().get('downloadProgress');
            this.$el.find('.progress-bar').css("width", progress + "%");
        },

        onDownloadFinished: function (bookResource, downloadedEpubid) {
            if (downloadedEpubid) {
                this._appView.navigateToEpub(
                    [
                        encodeURIComponent(bookResource.get('publisherRef')),
                        encodeURIComponent(bookResource.get('bookId')),
                        encodeURIComponent(downloadedEpubid)
                    ], { trigger: true });
            }
        }
    });
});

